//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
// import TextFieldGroup from "../common_input/TextFieldGroup";
// import { SelectField } from "../common_input/TextFieldGroup";

//Redux function
import { registerUser, setErrors } from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3Page1 from "./RegisterStep3Page1";
import RegisterStep3Page2 from "./RegisterStep3Page2";

import capitalize_first_letter from "../../App/validation/capitalize_first_letter";

const pages = [
  {
    step: 1,
    field_name: "organization",
  },
  {
    step: 1,
    field_name: "continent",
  },
  {
    step: 2,
    field_name: "industry",
  },
  {
    step: 2,
    field_name: "objective",
  },
  {
    step: 3,
    field_name: "first_name",
  },
  {
    step: 3,
    field_name: "last_name",
  },
  {
    step: 3,
    field_name: "name",
  },
  {
    step: 4,
    field_name: "phone_number",
  },
  {
    step: 4,
    field_name: "password",
  },
  {
    step: 4,
    field_name: "password2",
  }

]


class Register extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      name: "",
      email: "x",
      phone_number: "",
      password: "",
      password2: "",
      errors: {},
      industry: "",
      redeem_code: "",
      // show_password: false,
      organization: "",
      continent: "",
      objective: "",
    };

  }

  componentDidMount = () => {
    if (this.props.errors) {
      return {
        errors: this.props.errors,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps?.errors) !== JSON.stringify(this.props?.errors)) {
      this.setState({
        errors: this.props?.errors
      })
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { mode } = this.props;
    const {
      first_name,
      last_name,
      name,
      email,
      phone_number,
      password,
      password2,
      industry,
      redeem_code,
      organization,
      continent,
      objective
    } = this.state;
    let body = {
      full_name: `${first_name} ${last_name}`,
      name: name.toLowerCase().replace(/\s/g, ""),
      email,
      phone_number,
      password,
      password2,
      industry: industry,
      city: "Kota Bandung",
      organization,
      continent,
      objective
    };
    if (this.props.kode) {
      body = {
        full_name: `${first_name} ${last_name}`,
        name: name.toLowerCase().replace(/\s/g, ""),
        email,
        phone_number,
        password,
        password2,
        industry: mode === "personal" ? "Research and Education" : industry,
        specialist_code: this.props.kode,
        city: "Kota Bandung",
        organization,
        continent,
        objective
      };
    }

    await this.props.registerUser(body, redeem_code);
  };

  onChange = (e) => {
    let body = {}
    if (e.target.name === "name") {
      body = {
        [e.target.name]: e.target.value.replace(/\s/g, ""),
        errors: {
          ...this.state.errors
        }
      }
    } else {
      body = {
        [e.target.name]: e.target.value,
        errors: {
          ...this.state.errors
        }
      }
    }

    delete body.errors[e.target.name]
    this.setState(body);
    this.props.setErrors()
    this.error_handle(e.target.name, e.target.value)
  };

  set_error = (key, value) => {
    let errors = structuredClone(this.state.errors)
    errors[key] = value;
    this.setState({ errors })
  }

  remove_error = (key) => {
    let errors = structuredClone(this.state.errors);
    delete errors[key]
    this.setState({ errors })
  }

  error_handle = (field_name, value) => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    if (!value) {
      const name = capitalize_first_letter(String(field_name)).replaceAll("_", " ");
      if (name) {
        const text = dict[`${name} cannot be empty.`][language]
        return { field_name, text }
      }
    } else {
      this.remove_error(field_name)
    };
  }

  check_if_any_error = () => {
    const { set_registration_step, registration_step } = this.props;
    const { step } = registration_step
    // cari error yang belum diketahui
    let errors_local = [];
    let error_texts = {}
    pages.filter((page) => page.step <= step).forEach((field) => {
      const is_empty = !this.state?.[field.field_name]
      if (is_empty) {
        const { field_name, text } = this.error_handle(
          field?.field_name,
          !!this.state?.[field?.field_name]
        ) || {}

        error_texts[field_name] = text
        errors_local.push(field)
      }
    })

    if (errors_local?.length > 0) {
      this.setState({
        errors: error_texts
      })

      set_registration_step({
        step: errors_local?.[0]?.step
      });
      return errors_local?.[0]?.step
    }
    return false
  }

  set_page_to_error = () => {
    const { set_registration_step } = this.props;

    // cek error yang sudah ada di state, kemudian ke halaman error tersebut
    for (let page of pages) {
      if (this.state.errors?.[page.field_name]) {
        set_registration_step({
          step: page.step
        });
        break;
      }
    }

  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    // const industry_list = get_industries(language);

    // const industry_list_sorted = sortArray(industry_list, "title", true);

    const {
      errors,
      first_name,
      last_name,
      name,
      // email,
      phone_number,
      password,
      password2,
      // show_password,
      industry,
      // redeem_code,
      organization,
      continent,
      objective
    } = this.state;

    const { registration_step, set_registration_step } = this.props;
    const { loading } = this.props.auth;

    const { step } = registration_step

    let iconButton;
    const kode = this.props.kode;
    let kode_content;
    if (kode) {
      kode_content = (
        <section style={{ marginBottom: "10px" }}>
          Kode specialist: <b>{kode}</b>
        </section>
      );
    }
    if (loading) {
      iconButton = (
        <button
          className="button"
          type="submit"
          style={{
            padding: "5px 15px",
            backgroundColor: "#1265ae",
            marginTop: "1rem",
          }}
        >
          {dict["Registering..."][language]}
        </button>
      );
    } else {
      iconButton = (
        <button
          className="marginTop_20 rounded_5 bg_black text_white w_full h_50"
          type="submit"
          onClick={(e) => {
            const { errors } = this.state;
            if (Object.keys(errors)?.length > 0) {
              this.set_page_to_error()
            }
            else {
              if (step < 4) {
                const is_any_error = this.check_if_any_error()
                if (is_any_error) { //ada error
                  set_registration_step({
                    step: is_any_error
                  })
                } else { //kalo nggak ada error
                  set_registration_step({
                    step: registration_step?.step + 1
                  })
                }

              } else if (step === 4 && Object.keys(errors)?.length === 0) {
                this.onSubmit(e)
              }
            }
          }
          }
        >
          {
            step === 4 ? dict["Register"][language] :
              dict["Next"][language]
          }
        </button >
      );
    }


    let page_content = null;
    if (step === 1) {
      page_content =
        <RegisterStep1
          continent={continent}
          organization={organization}
          onChange={this.onChange}
          errors={errors}
        />
    } else if (step === 2) {
      page_content =
        <RegisterStep2
          industry={industry}
          objective={objective}
          onChange={this.onChange}
          errors={errors}
        />

    } else if (step === 3) {
      page_content =
        <RegisterStep3Page1
          first_name={first_name}
          last_name={last_name}
          name={name}
          onChange={this.onChange}
          errors={errors}
        />
    } else if (step === 4) {
      page_content =
        <RegisterStep3Page2
          phone_number={phone_number}
          password={password}
          password2={password2}
          onChange={this.onChange}
          errors={errors}
        />
    }

    const register_content = (
      <div>
        {kode_content}
        <div>
          {page_content}
          {iconButton}
        </div>
        <style>{`
           #bottom_bar{ display:none;}
        `}</style>
      </div>
    );

    // const default_content = (
    //   <div className="flex flex_col gap_10">
    //     <label>
    //       {dict["Select your account mode you want to register"][language]}
    //     </label>
    //     <section className="flex justify_center gap_10">
    //       <button
    //         className="button_inactive border padding_5 rounded_5 text_blue"
    //         onClick={() => handle_mode("personal")}
    //         data-mapid="clickPersonal"
    //       >
    //         Personal
    //       </button>
    //       <button
    //         className="button_inactive border padding_5 rounded_5 text_blue"
    //         data-mapid="clickCompany"
    //         onClick={() => handle_mode("company")}
    //       >
    //         Company
    //       </button>
    //     </section>
    //   </div>
    // );


    // let main_content = default_content;
    // if (mode !== "none") {
    //   main_content = register_content;
    // }

    let main_content = register_content;

    return <main>{main_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser, setErrors })(Register);
