//Library modul
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { setLanguage } from "../../App/actions/authActions";

//Picture Asset
import connected_future from "../../Assets/svg/connected_future.svg"
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";

//General Function
// import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";
import GeomapidVersion from "./GeomapidVersion";
import dict from "../../Data/dict.json";
import { ArrowBack } from "@material-ui/icons";

class NavigationLanding extends Component {
  state = {
    width: window.innerWidth,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  onSetLanguage = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";

    if (language === "ina") {
      this.props.setLanguage("eng");
    } else {
      this.props.setLanguage("ina");
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain = this.props.auth.domain;
    const width = window.innerWidth;
    const path = window.location.pathname;
    // const domain = "kai.mapid.io";
    // const language_content = (
    //   <button onClick={() => this.onSetLanguage()} className="button_language">
    //     {/* {e.name} */}
    //     {language === "ina" ? (
    //       <img
    //         src="https://mapid.co.id/img/icon/indo-flag.png"
    //         alt="language"
    //         width="100%"
    //         height="100%"
    //       />
    //     ) : (
    //       <img
    //         src="https://mapid.co.id/img/icon/uk-flag.png"
    //         alt="language"
    //         width="100%"
    //         height="100%"
    //       />
    //     )}
    //   </button>
    // );
    // let is_show = true;

    const content_3 = (
      <nav className="nav_login">
        <div className="nav_login_left">
          <Link to="/">
            <img src={domain_list?.[domain]?.logo} alt="mapid" />
          </Link>
        </div>
        {/* <div className="nav_login_right">
          <div>
            <a
              href="https://mapid.co.id/documentation"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLExQBc2gAI8uopeN3Xaj9V9b1QLPp8wPj"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial
            </a>
          </div>
          {language_content}
        </div> */}
        {width > 800 ? <div className="nav_login_right">
          <div>
            {
              (path === "/forget-password" || path === "/register") &&
              <Link className="marginLeft_30 pointer center_perfect" to="/login">
                <ArrowBack style={{ width: "20px", height: "20px" }} />
                <label className="marginLeft_10 pointer">{dict["Back to Sign In"][language]}</label>
              </Link>
            }
          </div>
          <Link to="/">
            <img src={connected_future} alt="Connected Future" />
          </Link>
        </div> :
          <GeomapidVersion is_mobile={true} title="GEO MAPID" subtitle="PT. Multi Areal Planing Indonesia" />
        }
      </nav>
    );
    return <div>{content_3}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLanguage })(NavigationLanding);
