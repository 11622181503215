//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup from "../common_input/TextFieldGroup";

//Redux function
import {
  email_forget_password,
  forget_password,
  code_forget,
  whatsapp_forget_password
} from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import history from "../../App/actions/history";
import checkContactType from "../../App/validation/checkContactType";

const countdown_value = 10; //second

const Messages = ({ color, title }) => {
  return (
    <p
      style={{
        fontSize: "0.7rem",
        color: `${color}`,
      }}
    >
      {title}
    </p>
  );
};

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email_or_whatsapp: "",
      whatsapp: "",
      username: "",
      password2: "",
      password3: "",
      messageCode: "",
      notSamePassword: "",
      errors: {},

      // show
      showcode: false,
      countdown: false,
      countdownfinished: false,
      changeShow: false,
      show_password: false,
      // cooldown time
      code: "",
      seconds: countdown_value,
    };
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  countDown = () => {
    let seconds = this.state.seconds - 1;

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.state.intervalTime);
      this.setState({
        countdown: false,
        countdownfinished: true,
        seconds: seconds,
      });
    } else {
      this.setState({
        countdown: true,
        seconds: seconds,
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmitEmail = (e) => {
    e.preventDefault();
    try {
      const { email_or_whatsapp, username } = this.state;
      const type = checkContactType(email_or_whatsapp);

      if (email_or_whatsapp === "" || username === "") {
        this.setState({
          messageEmail: 303,
        });
      } else {
        const lang = localStorage.getItem("language");
        let bodyLang = "en";
        if (lang === "ina") {
          bodyLang = "id";
        }

        let body = {
          username: username,
          lang: bodyLang,
        };

        if (type === "email") {
          body.email = email_or_whatsapp;
          this.props.email_forget_password(body);
        } else if (type === "whatsapp") {
          body.phone_number = email_or_whatsapp;
          this.props.whatsapp_forget_password(body);
        }

        this.setState({
          showcode: true,
          messageEmail: 202,
          intervalTime: setInterval(this.countDown, 1000),
          countdown: true,
          seconds: countdown_value,
        });

      }

    } catch (error) {
      this.setState({
        messageEmail: 404,
      });
    }

  };

  handleSubmitCode = (e) => {
    e.preventDefault();

    if (this.state.code === "") {
      this.setState({
        messageCode: 202,
      });
    } else {
      const { email, username, code } = this.state;
      const body = {
        username,
        email,
        code,
      };
      try {
        this.props.code_forget(body);
        this.setState({
          changeShow: !this.state.changeShow,
        });
      } catch (error) {
        this.setState({
          messageCode: 303,
        });
      }
    }
  };

  handleSubmitPassword = (e) => {
    e.preventDefault();
    const { password3, password2, email, username } = this.state;

    if (password2 === "" || password3 === "") {
      this.setState({
        notSamePassword: 404,
      });
    } else if (password2.length <= 8) {
      this.setState({
        notSamePassword: 505,
      });
    } else {
      if (password2 === password3) {
        // if (this.props.auth.user.is_email_confirm === "not_propose") {
        this.setState({
          changeShow: !this.state.changeShow,
        });
        // }
        const body = {
          email,
          username,
          password1: password2,
          password2: password3,
        };
        this.props.forget_password(body);
        history.push("/login");
      } else {
        this.setState({
          notSamePassword: 303,
        });
      }
    }
  };

  time_to_string = ({ h = 0, m = 0, s = 0 }) => {
    let min = "00";
    let sec = "00";
    let hour = "";

    if (h) {
      if (`${h}`.length === 1) {
        hour = `0${h}:`;
      } else {
        hour = `${h}:`;
      }
    }

    if (`${m}`.length === 1) {
      min = `0${m}`;
    } else {
      min = `${m}`;
    }

    if (`${s}`.length === 1) {
      sec = `0${s}`;
    } else {
      sec = `${s}`;
    }

    return `${hour}${min}:${sec}`;
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      errors,
      // email,
      // whatsapp,
      code,
      password2,
      password3,
      username,
      show_password,
      countdownfinished,
      countdown,
      seconds,
      email_or_whatsapp
    } = this.state;
    const { loading } = this.props.auth;
    const count_down_text = this.time_to_string(this.secondsToTime(seconds));

    let item_content = (
      <div>
        {this.state.changeShow ? (
          <div>
            <form>
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder={dict["Confirm password"][language]}
                name="password2"
                id="password2"
                value={password2}
                onChange={this.handleChange}
                error={errors.password2}
                toggleShowPassword={this.toggleShowPassword}
                pattern="[0-9a-zA-Z]{8,}"
              />
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder={dict["Password"][language]}
                name="password3"
                id="password3"
                value={password3}
                onChange={this.handleChange}
                error={errors.password3}
                toggleShowPassword={this.toggleShowPassword}
                pattern="[0-9a-zA-Z]{8,}"
              />
              {this.state.notSamePassword === 303 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Password not the same"][language]}
                />
              ) : this.state.notSamePassword === 404 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Please input password"][language]}
                />
              ) : this.state.notSamePassword === 505 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Minimum 8 characters"][language]}
                />
              ) : null}
              {loading ? (
                <button
                  className="button"
                  type="submit"
                  style={{
                    padding: "5px 15px",
                    backgroundColor: "#1265ae",
                    marginTop: "1rem",
                  }}
                >
                  {dict["Sending ......"][language]}
                </button>
              ) : (
                <button
                  className="button"
                  type="submit"
                  style={{
                    padding: "5px 15px",
                    backgroundColor: "#1265ae",
                    marginTop: "1rem",
                  }}
                  onClick={this.handleSubmitPassword}
                >
                  {dict["Sending"][language]}
                </button>
              )}
            </form>
          </div>
        ) : (
          <>
            {countdown ? (
              <>
                <TextFieldGroup
                  placeholder={dict["User name"][language]}
                  type="username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={this.handleChange}
                  error={errors.username}
                  disabled
                />
                <TextFieldGroup
                  placeholder={dict["Email"][language]}
                  type={"text"}
                  name={"email_or_whatsapp"}
                  id={"email_or_whatsapp"}
                  value={email_or_whatsapp}
                  onChange={this.handleChange}
                  error={errors.email_or_whatsapp}
                  disabled
                />
                {this.state.messageEmail === 202 ? (
                  <Messages
                    color="#008000"
                    title={dict["Code verification"][language]}
                  />
                ) : this.state.messageEmail === 303 ? (
                  <Messages
                    color="#ff0000"
                    title={
                      dict["Please input your username or email"][language]
                    }
                  />
                ) : this.state.messageEmail === 404 ? (
                  <Messages
                    color="#ff0000"
                    title={dict["Email not found"][language]}
                  />
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ fontSize: "15px" }}>{count_down_text}</p>
                </div>
              </>
            ) : (
              <>
                <TextFieldGroup
                  placeholder={dict["User name"][language]}
                  type="username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={this.handleChange}
                  error={errors.username}
                />
                <TextFieldGroup
                  placeholder={"Email/Whatsapp"}
                  type={"email_or_whatsapp"}
                  name={"email_or_whatsapp"}
                  id={"email_or_whatsapp"}
                  value={email_or_whatsapp}
                  onChange={this.handleChange}
                  error={errors.email_or_whatsapp}
                />
                {this.state.messageEmail === 202 ? (
                  <Messages
                    color="#008000"
                    title={dict["Code verification"][language]}
                  />
                ) : this.state.messageEmail === 303 ? (
                  <Messages
                    color="#ff0000"
                    title={
                      dict["Please input your username or email"][language]
                    }
                  />
                ) : this.state.messageEmail === 404 ? (
                  <Messages
                    color="#ff0000"
                    title={dict["Email not found"][language]}
                  />
                ) : null}
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: countdownfinished
                      ? "flex-start"
                      : "flex-end",
                  }}
                >
                  {loading ? (
                    <button
                      className="button"
                      type="submit"
                      style={{
                        padding: "5px 15px",
                        backgroundColor: "#1265ae",
                        marginTop: "1rem",
                      }}
                    >
                      {dict["Sending ......"][language]}
                    </button>
                  ) : (
                    <>
                      {!countdown && countdownfinished ? (
                        <div>
                          <label
                            style={{
                              color: "grey",
                            }}
                          >
                            {dict["Didn't get the code yet?"][language]}
                          </label>
                          <button
                            onClick={this.handleSubmitEmail}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              textDecoration: "underline",
                              color: "#1265ae",
                              fontWeight: "bold",
                            }}
                          >
                            {dict["Resend Code"][language]}
                          </button>
                        </div>
                      ) : (
                        <button
                          className="marginTop_20 button_inactive rounded_5 bg_black text_white w_full h_50"
                          type="submit"
                          onClick={this.handleSubmitEmail}
                        >
                          {dict["Sending"][language]}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {this.state.showcode && (
              <div>
                <section
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextFieldGroup
                    placeholder={dict["Code"][language]}
                    name="code"
                    id="code"
                    value={code}
                    onChange={this.handleChange}
                    error={errors.code}
                  />
                </section>
                <section>
                  <button
                    className="marginTop_20 button_inactive rounded_5 bg_black text_white w_full h_50"
                    type="submit"
                    onClick={this.handleSubmitCode}
                  >
                    {dict["Submit"][language]}
                  </button>
                </section>
                <section>
                  {this.state.messageCode === 202 ? (
                    <Messages
                      color="#ff0000"
                      title={dict["please input your code"][language]}
                    />
                  ) : this.state.messageCode === 303 ? (
                    <Messages
                      color="#ff0000"
                      title={dict["Code not match"][language]}
                    />
                  ) : null}
                </section>
              </div>
            )}
          </>
        )}
      </div>
    );
    return <main>{item_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  email_forget_password,
  forget_password,
  code_forget,
  whatsapp_forget_password
})(ForgetPassword);
