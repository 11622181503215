/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
// import maplibregl from "maplibre-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*GENERAL FUNCTION & DATA*/
import colors_layer_poi from "../../Data/colors_layer_poi.json";

/*NON IMPORT*/

class LAYER_S_POI extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const map_object_before = prevProps.layer.map_object;
    const map_object_after = this.props.layer.map_object;
    if (
      map_object_after &&
      (state_update_after !== state_update_before ||
        map_object_after !== map_object_before)
    ) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const { map_object } = this.props.layer;
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_circle_radius,
    } = this.props.sini;

    const visibility = sini_map_show.includes("poi") ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    merge_array.forEach((item) => {
      const { request_id } = item;
      const features = item?.poi_features || [];
      const geojson = {
        type: "FeatureCollection",
        features: features,
      };
      const layer_id = `poi_${request_id}`;
      if (!map_object.getSource(layer_id)) {
        map_object.addSource(layer_id, {
          type: "geojson",
          data: geojson,
        });
      } else {
        map_object.getSource(layer_id).setData(geojson);
      }

      if (!map_object.getLayer(layer_id)) {
        map_object.addLayer({
          id: layer_id,
          source: layer_id,
          type: "circle",
          paint: {
            "circle-color": colors_layer_poi,
            "circle-radius": Number(sini_circle_radius),
            "circle-stroke-width": 1,
            "circle-stroke-color": "#000",
          },
          layout: {
            visibility: visibility,
          },
        });
      } else {
        map_object.setLayoutProperty(layer_id, "visibility", visibility);
      }
    });
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LAYER_S_POI);
