/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
// import PaymentTable from "../../Components/payment/PaymentTable";
import UploadPhotoProfile from "../../Components/user/UploadPhotoProfile";
import Modal from "../../Components/common_modal/Modal";
import SpinnerSimple from "../../Components/common_spinner/SpinnerSimple";

/*REDUX FUNCTION*/
import {
  logoutUser,
  getMapAccess,
  initApi,
  set_modal_quota_access,
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
} from "../../App/actions/authActions";
import { clearProjectList } from "../../App/actions/projectActions";
import { clearFormList } from "../../App/actions/layerNewActions";
import { getUserPaymentList } from "../../App/actions/paymentActions";
import { getGroups } from "../../App/actions/groupActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

/*GENERAL FUNCTION & DATA*/
import * as Validation from "../../App/validation";
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";
import format_date_short from "../../App/validation/format_date_short";

/*NON IMPORT*/

const Paragrah = ({ title, value }) => {
  return (
    <div className="detail_profile_paragraf">
      <p>{title}</p>
      {value ? (
        <span
          style={{
            maxWidth: 200,
            wordBreak: "break-word",
          }}
        >
          {value}
        </span>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_delete: false,
      modal_edit: false,
      modal_add_member: false,
      modal_device: false,
      modalBuyNow: false,
      modalTopUp: false,
      modalShowPricing: false,
      modal_token: false,
    };
  }

  componentDidMount() {
    this.props.get_quota_access();
    this.props.get_quota_access_sini_ai();
    this.props.get_quota_ai_chat();
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.getMapAccess();
    if (this?.props?.match?.params?.status === "pricing") {
      this.setState({ modalShowPricing: true });
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  componentDidUpdate(prevProps) {
    //close modal
    const { groups } = this.props.auth;
    const groups_prev = prevProps.auth.groups;
    if (!Validation.isEqual(groups, groups_prev)) {
      this.setState({ modal: false, modal_edit: false });
    } else {
      return null;
    }
  }

  toggle_buy_license = () => {
    const { modal_buy_license } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_buy_license",
      value: !modal_buy_license,
    });
  };

  initApi = () => {
    const body = {};
    this.props.initApi(body);
  };

  toggle_token = () => {
    this.setState({ modal_token: !this.state.modal_token });
  };

  onLogoutClick() {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  }

  togglePricing = () => {
    this.setState(
      {
        modalShowPricing: !this.state.modalShowPricing,
      },
      () => {
        if (this.state.modalShowPricing) {
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleDevice = () => {
    this.setState({
      modal_device: !this.state.modal_device,
    });
  };

  toggleBuyNow = () => {
    this.setState({ modalBuyNow: !this.state.modalBuyNow });
  };

  toggleTopUp = () => {
    this.setState({ modalTopUp: !this.state.modalTopUp });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { loadingDetail, map_access, max_quota, domain } = this.props.auth;
    const {
      // modalShowPricing,
      modal_token,
    } = this.state;
    const { user, quota_access, modal_quota_access } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const quota_ai_chat = this.props.auth.quota_ai_chat || 0;

    //content
    // console.table({ quota_access_sini_ai, quota_ai_chat });
    const status_domain = domain_list?.[domain]?.status;
    let delta_access = max_quota - map_access;
    if (delta_access < 0) {
      delta_access = 0;
    }

    const api_keys = user?.api_keys ? user.api_keys : [];

    const url_pic =
      user && user.profile_picture && user.profile_picture.url
        ? user.profile_picture.url
        : pic_static;
    let itemContent;

    //user
    let word_expired_user;
    // let id_exp_button_user = "blue";

    if (license_user_status.payment_type) {
      const { active_days } = license_user_status;
      const month = parseInt(Number(active_days) / 30);
      const days = parseInt(Number(active_days) % 30);
      if (active_days < 0) {
        word_expired_user = `${dict?.["Has expired"]?.[language]}`;
        // id_exp_button_user = "red";
      } else if (active_days === 0) {
        word_expired_user = `${dict?.["Expired today"]?.[language]}`;
        // id_exp_button_user = "red";
      } else if (month > 12) {
        word_expired_user = `${dict?.["Active until"]?.[language]
          } ${format_date_short(license_user_status?.date_finish)}`;
        // id_exp_button_user = "blue";
      } else if (active_days >= 30) {
        word_expired_user = `${dict?.["Active period"]?.[language]} ${month} ${dict?.["months"]?.[language]} ${days} ${dict?.["days"]?.[language]}`;
        // id_exp_button_user = "blue";
      } else {
        word_expired_user = `${dict?.["Active period"]?.[language]} ${active_days} ${dict?.["days"]?.[language]}`;
        // id_exp_button_user = "blue";
      }
    } else {
      word_expired_user = dict["Upgrade"][language];
    }

    //group
    let word_expired_group;
    // let id_exp_button_group = "blue";

    if (license_group_status.payment_type) {
      const { active_days } = license_group_status;
      const month = parseInt(Number(active_days) / 30.5);
      if (active_days < 0) {
        word_expired_group = `${dict?.["Has expired"]?.[language]}`;
        // id_exp_button_group = "red";
      } else if (active_days === 0) {
        word_expired_group = `${dict?.["Expired today"]?.[language]}`;
        // id_exp_button_group = "red";
      } else if (month > 12) {
        word_expired_group = `${dict?.["Active until"]?.[language]
          } ${format_date_short(license_group_status?.date_finish)}`;
        // id_exp_button_group = "blue";
      } else if (active_days > 30.5) {
        word_expired_group = `${dict?.["Active period"]?.[language]} ${month} ${dict?.["months"]?.[language]}`;
        // id_exp_button_group = "blue";
      } else {
        word_expired_group = `${dict?.["Active period"]?.[language]} ${active_days} ${dict?.["days"]?.[language]}`;
        // id_exp_button_group = "blue";
      }
    } else {
      // word_expired_group = dict["Upgrade"][language];
      word_expired_group = "No company license"
    }

    if (loadingDetail && Validation.isEmpty(user)) {
      itemContent = (
        <SpinnerSimple
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else {
      //user
      let license_name_user = "No user license";
      switch (license_user_status.payment_type) {
        case "license_0":
          license_name_user = "No user license";
          break;
        case "license_1":
          license_name_user = "Personal";
          break;
        case "license_2":
          license_name_user = "Business";
          break;
        case "license_3":
          license_name_user = "Enterprise";
          break;
        case "license_academy":
          license_name_user = "Academy";
          break;
        default:
          license_name_user = "No user license";
          break;
      }

      //group
      let license_name_group = "No company license";

      if (
        (license_user_status.payment_type === "license_2" ||
          license_user_status.payment_type === "license_3") &&
        license_user_status.is_has_license &&
        !license_group_status.payment_type
      ) {
        license_name_group = "Anda belum membuat group";
      } else {
        switch (license_group_status.payment_type) {
          case "license_2":
            license_name_group = "Business";
            break;
          case "license_3":
            license_name_group = "Enterprise";
            break;
          case "license_academy":
            license_name_group = "Academy";
            break;
          default:
            license_name_group = "No company license";
            break;
        }
      }

      const sini_access_content = (
        <main>
          <div>{quota_access} akses</div>
          {quota_access_sini_ai > 0 && (
            <div>{quota_access_sini_ai} titik trial</div>
          )}
          {quota_ai_chat > 0 && <div>{quota_ai_chat} chat trial</div>}
        </main>
      );

      let content_array = [
        {
          key: "license_user",
          bubble: (
            <label
              // onClick={this.toggle_buy_license}
              className="text_small"
            // id={id_exp_button_user}
            >
              {word_expired_user}
            </label>
          ),
          name: "User License",
          footer: license_name_user,
        },
        {
          key: "license_group",
          bubble: (
            <div
              // onClick={this.toggle_buy_license}
              className="text_small"
            // id={id_exp_button_group}
            >
              {word_expired_group}
            </div>
          ),
          name: "Company License",
          footer: (
            <div>
              {license_group_status?.payment_type ? (
                <>
                  {license_name_group} support by{" "}
                  <b>
                    {license_group_status?.user?.full_name
                      ? license_group_status?.user?.full_name
                      : license_group_status?.user?.email}
                  </b>
                </>
              ) : (
                <>{license_name_group}</>
              )}
            </div>
          ),
        },
        {
          key: "payment",
          bubble: (
            <Link
              to="/purchase_list"
              className="button_very_small background_blue"
            >
              {dict["History"][language]}
            </Link>
          ),
          name: dict["PAYMENT"][language],
          footer: dict["Your payment history"][language],
        },
        {
          key: "quota",
          bubble: (
            <button
              onClick={() =>
                this.props.set_modal_quota_access(!modal_quota_access)
              }
              className="button_very_small background_blue"
            >
              Beli kuota insight
            </button>
          ),
          name: sini_access_content,
          footer: "Akses untuk INSIGHT, SINI dan SINI AI",
        },
        {
          key: "api_key_token",
          bubble: (
            <button
              onClick={this.toggle_token}
              className="button_very_small background_blue"
            >
              Detail
            </button>
          ),
          name: "API Key Token",
          footer: "Secret token for REST API",
        },
      ];

      const content_render = content_array.map((e, idx) => {
        return (
          <main
            key={idx}
            style={{
              backgroundColor: "#fff",
              color: "#556573ff",
              borderRadius: "10px",
              width: "160px",
              maxWidth: "90%",
              padding: "10px",
              display: "inline-block",
              marginRight: "10px",
              marginBottom: "10px",
              wordBreak: "break-word",
              verticalAlign: "top",
              minHeight: "110px",
              position: "relative",
            }}
          >
            <section style={{ textAlign: "right" }}>{e.bubble}</section>
            <section
              className="text_bold"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {e.name}
            </section>
            <section
              className="text_inferior"
              style={{ position: "absolute", bottom: "5px" }}
            >
              {e.footer}
            </section>
          </main>
        );
      });

      itemContent = (
        <main>
          <section>
            <div className="detail_profile_wrapper">
              <div
                style={{
                  marginRight: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "77px",
                    width: "77px",
                    display: "block",
                    border: "5px solid #dedede",
                    borderRadius: "13px",
                    backgroundImage: `url(${url_pic})`,
                    backgroundSize: "cover",
                  }}
                />
                <UploadPhotoProfile />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "23px",
                      fontWeight: "470",
                      lineHeight: "110%",
                      marginRight: "1rem",
                    }}
                  >
                    {user.full_name ? user.full_name : user.name}
                  </h1>
                  <Link
                    to={`/editprofile`}
                    className="edit_button_profile"
                    data-mapid="clickEdit"
                  >
                    {dict["Edit profile"][language]}
                  </Link>
                  <div
                    onClick={this.onLogoutClick.bind(this)}
                    className="logout_button_profile"
                  >
                    {dict["Sign out"][language]}
                  </div>
                </div>
                <p style={{ textAlign: "justify", padding: "0 0.5rem" }}>
                  {user.bio}
                </p>
                <div className="detail_profil_value">
                  <Paragrah
                    title={dict["Username"][language]}
                    value={user.name}
                  />
                  <Paragrah title="E-mail" value={user.email} />
                  <Paragrah
                    title={dict["phone number"][language]}
                    value={user.phone_number}
                  />
                  <Paragrah
                    title={dict["Birthday"][language]}
                    value={user.birthday}
                  />
                  <Paragrah
                    title={dict["industry"][language]}
                    value={user.industry}
                  />
                </div>
              </div>
            </div>
          </section>
          <br />
          {status_domain !== "premium" && (
            <main>
              <br />
              {content_render}
              {/* <section>
                <br />
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <div onClick={this.togglePricing} className="button_standard">
                    {dict["Open Pricing"][language]}
                  </div>
                </div>
                {((!license_user_status?.active_days > 0 &&
                  !license_group_status?.active_days > 0) ||
                  modalShowPricing) && (
                  <>
                    <h1 className="text_header margin_bottom">
                      {dict["GEO MAPID License"][language]}
                    </h1>
                    <PaymentTable />
                  </>
                )}
              </section> */}
            </main>
          )}
        </main>
      );
    }

    const modal_token_content = modal_token && (
      <Modal modalSize="small" isOpen={modal_token} onClose={this.toggle_token}>
        <div className="box-body">
          <h1 className="text_bold">API key token</h1>
          <br />
          {api_keys.length > 0 ? (
            <section>
              <p className="text_inferior">
                This is your API key, use it to unlock API access
              </p>
              <p className="text_bold">{api_keys[0]?.key}</p>
            </section>
          ) : (
            <section>
              <p className="text_inferior">You don't have an API key yet</p>
              <button className="button_standard" onClick={this.initApi}>
                Create a new API key
              </button>
            </section>
          )}
        </div>
      </Modal>
    );

    return (
      <div
        className="main_container footer_margin"
        style={{ overflowX: "hidden" }}
      >
        {itemContent}
        {modal_token_content}
        <Helmet>
          <title>{dict["Profile"][language]}</title>
          <meta name="description" content={dict["Profile"][language]} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  properties: state.properties,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  logoutUser,
  getUserPaymentList,
  getGroups,
  getMapAccess,
  clearProjectList,
  initApi,
  clearFormList,
  set_modal_quota_access,
  set_value_properties,
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
})(UserDashboard);
