/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import maplibregl from "maplibre-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/
import ICON_MARKER from "../../Assets/jsx/ICON_MARKER";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class LAYER_S_MARKER extends Component {
  componentDidUpdate(prevProps) {
    const { sini_marker_active, loading_item_array } = this.props.sini;
    const { loading_item_array: prev_loading_item_array } =
      prevProps.loading_reducer;

    // Re-render marker if active marker coordinates or loading state changes
    if (
      sini_marker_active !== prevProps.sini_marker_active ||
      loading_item_array !== prev_loading_item_array
    ) {
      this.render_marker();
    }
  }

  render_marker = () => {
    const { sini_marker_active } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;
    const { map_object } = this.props.layer;

    const long = sini_marker_active?.long;
    const lat = sini_marker_active?.lat;

    // Ensure map_object and coordinates exist
    if (!map_object || long === undefined || lat === undefined) return;

    // Remove any existing marker with the same identifier
    document
      .querySelectorAll(".mapboxgl-marker-s-marker")
      .forEach((marker) => marker.remove());

    // Render marker only if loading is active for the specified actions
    if (
      (loading_item_array.includes("sini_load_parent") ||
        loading_item_array.includes("get_ai_parent_object")) &&
      long !== undefined &&
      lat !== undefined
    ) {
      const marker_element = document.createElement("div");
      marker_element.className = "mapboxgl-marker-s-marker"; // Unique class for the marker

      const marker_html = ReactDOMServer.renderToString(
        <div>
          <ICON_MARKER />
        </div>
      );
      marker_element.innerHTML = marker_html;

      const lngLat = new maplibregl.LngLat(long, lat);
      const point = map_object.project(lngLat);

      marker_element.style.position = "absolute";
      marker_element.style.transform = "translate(-50%, -100%)";
      marker_element.style.left = `${point.x}px`;
      marker_element.style.top = `${point.y}px`;

      map_object.getContainer().appendChild(marker_element);

      // Adjust position on map move
      map_object.on("move", () => {
        const newPoint = map_object.project(lngLat);
        marker_element.style.left = `${newPoint.x}px`;
        marker_element.style.top = `${newPoint.y}px`;
      });
    }
  };

  render() {
    return null; // Marker is rendered directly on the map
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LAYER_S_MARKER);
