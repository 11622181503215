const initial_state = {
  //basic
  loading_status: false,
  loading_item: "",
  admin_information_list: [],
  quota_price_list: [],
  error_object: {},
  sidebar_right_status: true,
  sidebar_left_status: true,
  folder_kai_timeseries: {},
  folder_list_active: [],
  poi_tipe_1_selected: [],
  poi_tipe_2_selected: [],
  poi_all_selected: false,
  save_layer_progress: 0,
  dashboard_menu_selected: "project", //project, bi, form, map_service
  legend_status: false,
  basemap_used: "2d", //2d, 3d, light, dark, sat
  //fitur share layer & search layer
  feature_will_be_share: {},
  feature_will_be_shared_key: "",
  mode_search: "location", //layer || location || search_inside
  search_input: "",
  layer_id_filtered: [],
  place_list_result: [],
  layer_id_search_inside: "",
  layer_name_search_inside: "",
  location_result_list: [],
  //list get administrasi by API
  list_all_provinsi: [],
  list_all_kota: [],
  list_all_kecamatan: [],
  list_all_kelurahan: [],
  //list administrasi by search
  list_kota_by_search: [],
  list_kecamatan_by_search: [],
  list_kelurahan_by_search: [],
  capex_value: 0,
  mode_grid: "size_grid", //size_grid || count_grid
  shared_link_list: [],
  shared_link_check: {},
  user_search_object: {},
  group_search_list: [],
  //state insight
  demographic_parent: "UMUM",
  demographic_child_idx_list: [0],
  chart_js_aggregate_train: {},
  feature_key_selected: "",
  feature_object_selected: {},
  //pop_up layer
  layer_id_popup: "",
  popupInfo: null,
  //state-state untuk algoritma genangan
  success_status: false,
  error_status: false,
  error_message: "",
  tipe_genangan: "",
  total_progress_genangan: 10,
  current_progress_genangan: 0,
  total_progress_genangan_global: 3,
  current_progress_genangan_global: 0,
  //state untuk layer styling
  layer_type: "",
  default_style_key: "",
  is_cluster: false,
  style_array: [],
  fields_edit_style: [],
  fields_edit_style_filtered: [],
  fields_style_true: [],
  features_length: 0,
  paint_object_edited: {},
  style_parameter: {
    paint_object: {},
    key: "",
    field_key: "",
    case_recomendation: "",
    color_logic: "", //constant, use_existing, by_text, by_number_gradient, by_number_step
    color_mode: "",
    value_array: [],
    type_of_object: {},
    type_of_array: [],
    min: 0,
    max: 0,
    steps: 5,
    is_number_count: [],
    constant_color: "#a0d062",
    style_type: "", //circle, line, fill
    is_cluster: false,
  },
  layer_style_step: 0,
  is_preview_on: false,
  //kumpulan state modal
  modal_buy_license: false,
  modal_share: false,
  modal_pop_up_genangan: false,
  modal_pop_up_layer: false,
  modal_edit_label_config: false,
  modal_input: false,
  modal_redeem: false,
};
export default function propertiesReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_loading":
      return {
        ...state,
        loading_item: action.payload,
        loading_status: true,
      };
    case "clear_loading":
      return {
        ...state,
        loading_item: "",
        loading_status: false,
      };
    case "set_value_properties":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_properties":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "pull_value_properties_string":
      return {
        ...state,
        ...pull_value_properties_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };
    case "pull_value_properties_object":
      return {
        ...state,
        ...pull_value_properties_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };
    default:
      return state;
  }
}
const pull_value_properties_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};
const pull_value_properties_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};
