//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Register from "../../Components/auth/Register";
import NavigationLanding from "../../Components/layout/NavigationLanding";
import LandingAuth from "../../Components/layout/LandingAuth";

//Redux function
import { openModal, setLanguage } from "../../App/actions/authActions";

//General Function
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";
import WhatsappConfirm from "../../Components/layout/WhatsappConfirm";

class LandingOTP extends Component {
  state = {
    width: window.innerWidth,
  };

  componentDidMount() {
  }


  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain = this.props.auth.domain;

    return (
      <>
        <LandingAuth
          nav={<NavigationLanding />}
          title={domain_list?.[domain]?.title}
          subtitle={domain_list?.[domain]?.sub_title}
          content={<WhatsappConfirm />}
          from="otp"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal, setLanguage })(
  LandingOTP
);
