/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_GENANGAN extends Component {
  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      this.props.sini.state_update !== prevProps.sini.state_update ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render_content();
    }
  }

  on_render_content = () => {
    const { features_genangan } = this.props.sini;
    const { genangan_view_status } = this.props.map;
    const { map_object } = this.props.layer;

    if (features_genangan.length > 0 && map_object) {
      const geojson_genangan = {
        type: "FeatureCollection",
        features: features_genangan,
      };

      // Remove existing source and layer if they already exist
      if (map_object.getSource("GENANGAN_CLOUD")) {
        this.remove_genangan_layer();
      }

      // Add the geojson source for genangan
      map_object.addSource("GENANGAN_CLOUD", {
        type: "geojson",
        data: geojson_genangan,
      });

      // Add the fill layer to display the genangan
      map_object.addLayer(
        {
          id: "GENANGAN_CLOUD",
          type: "fill",
          source: "GENANGAN_CLOUD",
          paint: {
            "fill-color": "rgb(255, 13, 13)",
            "fill-opacity": 0.5,
          },
          layout: {
            visibility: genangan_view_status ? "visible" : "none",
          },
        },
        "building"
      );

      // Add a click event for the genangan layer
      map_object.on("click", "GENANGAN_CLOUD", (e) => {
        if (this.props.layerOnClick) {
          this.props.layerOnClick(e);
        }
      });
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_GENANGAN);
