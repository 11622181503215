import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Modal from "../common_modal/Modal";
import VersionLog from "./VersionLog";
import domain_list from "../../Data/domain_list";
import { setLanguage } from "../../App/actions/authActions";

import GeomapidVersion from "./GeomapidVersion";
import connected_future from "../../Assets/svg/connected_future.svg"

const default_style = `linear-gradient(162deg,
      rgba(147, 197, 253, 1) 5%,
      rgba(12, 165, 235, 1) 53%,
      rgba(134, 239, 172, 1) 94%)`

class LandingAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_version: false
    }
  }

  toggle_version = () => {
    const { modal_version } = this.state
    this.setState({
      modal_version: !modal_version
    })
  };

  onSetLanguage = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    if (language === "ina") {
      this.props.setLanguage("eng");
    } else {
      this.props.setLanguage("ina");
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let { nav, content, auth, registration_step_progress, from } = this.props;
    const { modal_version } = this.state;

    let { domain, version } = auth;
    const width = window.innerWidth;
    // domain = "befa.mapid.io";
    // const [modal_version, setModal_version] = useState(false);


    const is_background = domain_list?.[domain]?.background !== "";
    const background = `url(${domain_list?.[domain]?.background})`

    const language_content = (
      <div className="marginRight_50">
        <div className="flex_between gap_5">
          <button onClick={() => this.onSetLanguage()}
            style={{
              width: "100px",
              height: "50px",
            }}
            className={`button_language ${language === "eng" ? "active" : ""}`}>
            <img
              src="https://mapid.co.id/img/icon/uk-flag.png"
              alt="language"
              height="28px"
              className="inline"
            />
            <label className="inline">En</label>
          </button>

          <div className="v_line h_30" />

          <button onClick={() => this.onSetLanguage()}
            style={{
              width: "100px",
              height: "50px",
            }}
            className={`button_language ${language === "ina" ? "active" : ""}`}>
            <img
              src="https://mapid.co.id/img/icon/indo-flag.png"
              alt="language"
              height="28px"
            />
            <label className="inline">ID</label>
          </button>
        </div>
      </div>
    );

    const parent_content = (
      <>
        {registration_step_progress}
        {language_content}
      </>
    )

    return (
      <>
        {modal_version && (
          <Modal
            modalSize="medium"
            id="modal"
            isOpen={modal_version}
            onClose={this.toggle_version}
          >
            <div className="box-body">
              <VersionLog />
            </div>
          </Modal>
        )}
        <main className="landing_auth">
          <section>
            <div>
              {nav}
              <section className="auth_content">
                <div className="auth_left"
                  style={{
                    // backgroundImage: is_background ? background : default_style
                    backgroundImage: background
                  }}
                >
                  {
                    window.innerWidth > 800 && <GeomapidVersion {...this.props} />
                  }
                </div>

                <div className="auth_right">
                  <div className={`flex flex_col align_end h_50`} style={{
                    paddingTop: width < 800 ? "20px" : "0px"
                  }}>
                    {width < 800 && <div style={{ margin: "0 50px 20px 0" }}>
                      <Link to="/">
                        <img src={connected_future} alt="Connected Future" />
                      </Link>
                    </div>}

                    {from === "register" ?
                      <div className="w_full flex_between">{parent_content}</div> :
                      <div className={`w_full flex_end`}>
                        <div>
                          {parent_content}
                        </div>
                      </div>
                    }
                  </div>
                  <div className="auth_content_right">
                    {content}
                  </div>
                </div>

              </section>
            </div>
          </section>


          <style>{`
  body{
    padding-top: 0px;
    overflow-y: auto;
  }
  .navbarDefault{ visibility: hidden;}
  #top_nav{ visibility: hidden;}
  #sidebar_home{ visibility: hidden;}
  .nav_bottom{ visibility: hidden;}
  `}</style>

        </main >
      </>
    )
  }
}



// const LandingAuth = ({ nav, title, subtitle, content, auth, mode }) => {
//   const [language, setLang] = useState(useLanguage()?.language)

//   let { domain, version } = auth;
//   // domain = "befa.mapid.io";
//   const [modal_version, setModal_version] = useState(false);

//   const toggle_version = () => setModal_version(!modal_version);

//   const onSetLanguage = () => {
//     if (language === "ina") {
//       setLanguage("eng");
//       setLang("eng")
//     } else {
//       setLanguage("ina");
//       setLang("ina")
//     }
//   };

//   const is_background = domain_list?.[domain]?.background !== "";
//   const background = `url(${domain_list?.[domain]?.background})`

//   const language_content = (
//     <button onClick={() => onSetLanguage()} className="button_language">
//       {/* {e.name} */}
//       {language === "ina" ? (
//         <img
//           src="https://mapid.co.id/img/icon/indo-flag.png"
//           alt="language"
//           width="100%"
//           height="100%"
//         />
//       ) : (
//         <img
//           src="https://mapid.co.id/img/icon/uk-flag.png"
//           alt="language"
//           width="100%"
//           height="100%"
//         />
//       )}
//     </button>
//   );

//   return (
//     <>
//       {modal_version && (
//         <Modal
//           modalSize="medium"
//           id="modal"
//           isOpen={modal_version}
//           onClose={toggle_version}
//         >
//           <div className="box-body">
//             <VersionLog />
//           </div>
//         </Modal>
//       )}
//       <main className="landing_auth">
//         <section>
//           <div>
//             {nav}
//             <section className="auth_content">
//               {mode !== "none" &&
//                 <div className="auth_left"
//                   style={{
//                     // backgroundImage: is_background ? background : default_style
//                     backgroundImage: background
//                   }}
//                 >
//                   <div className="auth_content_left w_350">
//                     <h4> {title} {" "}
//                       <button className="hover_bigger" onClick={toggle_version}>
//                         <img src={information} alt="What's new" />
//                       </button>
//                     </h4>
//                     <h5>{subtitle}</h5>
//                     <div>
//                       <label>
//                         Version {version}
//                       </label>
//                       <label className="padding_x_5">{" . "}</label>
//                       <Link
//                         className="center_perfect bold text_white"
//                         to={{ pathname: "https://mapid.co.id" }}
//                         target="_blank" rel="noopener noreferer"
//                       >
//                         <u>About Us</u> <img src={arrow} alt="Landing Page" />
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               }

//               <div className="auth_right">
//                 <div className="auth_content_right border">
//                   {content}
//                   <div className="nav_login_right">
//                     {language_content}
//                   </div>
//                 </div>
//               </div>

//             </section>
//           </div>
//         </section>


//         <style>{`
//   body{
//     padding-top: 0px;
//     overflow-y: auto;
//   }
//   .navbarDefault{ visibility: hidden;}
//   #top_nav{ visibility: hidden;}
//   #sidebar_home{ visibility: hidden;}
//   .nav_bottom{ visibility: hidden;}
//   `}</style>

//       </main >
//     </>
//   );
// };

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setLanguage
})(LandingAuth);
