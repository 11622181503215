/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user } from "../../App/actions/authActions";
import { status_action } from "../../App/actions/mapActions";
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/
import icon_zoom_in from "../../Assets/svg/icon_zoom_in.svg";
import icon_zoom_out from "../../Assets/svg/icon_zoom_out.svg";
import icon_compass from "../../Assets/svg/icon_compass.svg";
import icon_gps from "../../Assets/svg/icon_gps.svg";
import icon_sini_with_text from "../../Assets/svg/icon_sini_with_text.svg";

/*GENERAL FUNCTION & DATA*/
import generate_uuid from "../../App/validation/generate_uuid";

/*NON IMPORT*/

class MAP_CONTROL extends Component {
  state = {
    geo_location_status: "pending", //pending, loading,  error, browser_not_supported, success
    geo_location_error: "",
    modal_change_map: false,
  };

  componentDidMount() {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.on("rotate", this.update_compass_rotation);
    }
  }

  componentDidUpdate(prevProps) {
    const { map_object } = this.props.layer;
    const map_object_prev = prevProps.layer.map_object;
    if (map_object_prev !== map_object && !!map_object) {
      map_object.on("rotate", this.update_compass_rotation);
    }
  }

  componentWillUnmount = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.off("rotate", this.update_compass_rotation);
    }
  };

  toggle_sini = () => {
    this.props.set_value_bi({
      key: "modal_sini",
      value: !this?.props?.bi?.modal_sini,
    });
  };

  toggle_change_map = (event) => {
    this.setState(
      {
        modal_change_map: !this.state.modal_change_map,
        anchor: event.currentTarget,
      },
      () => {}
    );
  };

  update_compass_rotation = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      const bearing = map_object.getBearing();
      const compass = document.getElementById("map_compass");
      if (compass) {
        compass.style.transform = `rotate(${-bearing}deg)`;
      }
    }
  };

  on_zoom_in = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.zoomIn();
    }
  };

  on_zoom_out = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.zoomOut();
    }
  };

  on_reset_compass = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.rotateTo(0, {
        duration: 1000,
      });
      map_object.once("moveend", () => {
        map_object.easeTo({
          pitch: 0,
          duration: 1000,
        });
      });
    }
  };

  on_geolocate = () => {
    this.setState({
      geo_location_status: "loading",
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const uuid = generate_uuid();
          const {
            latitude,
            longitude,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
          } = position.coords;
          const gps_object = {
            uuid: uuid,
            latitude,
            longitude,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
          };
          this.props.set_value_layer({
            key: "gps_object",
            value: gps_object,
          });
          this.setState({
            geo_location_status: "success",
          });
        },
        (error) => {
          this.setState({
            geo_location_status: "error",
            geo_location_error: error,
          });
        }
      );
    } else {
      this.setState({
        geo_location_status: "browser_not_supported",
      });
    }
  };

  render() {
    const { geo_location_status } = this.state;

    let gps_class = "";
    if (geo_location_status === "loading") {
      gps_class = "icon_rotate";
    } else if (geo_location_status === "success") {
      gps_class = "icon_scale";
      setTimeout(() => {
        const icon_gps = document.getElementById("icon_gps");
        if (icon_gps) {
          icon_gps.classList.remove("icon_scale");
          icon_gps.classList.add("icon_reset");
        }
      }, 1000);
    }

    return (
      <main>
        <aside className="map_controls_wrapper_bi">
          <nav className="map_controls_background">
            <section className="map_controls_container">
              <button
                className="map_button_draw zoom_in"
                onClick={this.on_zoom_in}
              >
                <img alt="Zoom In" src={icon_zoom_in} width={12} />
              </button>
              <div className="separator"></div>
              <button
                className="map_button_draw zoom_out"
                onClick={this.on_zoom_out}
              >
                <img alt="Zoom Out" src={icon_zoom_out} width={12} />
              </button>
              <div className="separator"></div>
              <button
                className="map_button_draw reset_compass"
                onClick={this.on_reset_compass}
              >
                <img
                  id="map_compass"
                  alt="Reset Compass"
                  src={icon_compass}
                  height={17}
                />
              </button>
              <div className="separator"></div>
              <button
                className="map_button_draw gps_map"
                onClick={this.on_geolocate}
              >
                <img
                  id="icon_gps"
                  alt="Geolocate"
                  src={icon_gps}
                  width={15}
                  className={gps_class}
                />
              </button>
            </section>
          </nav>
        </aside>

        <button
          className="map_button_control_bi sini_map_bi"
          onClick={this.toggle_sini}
        >
          <img alt="S" src={icon_sini_with_text} width={28} />
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  set_value_properties,
  set_value_user,
  status_action,
  set_value_bi,
})(MAP_CONTROL);
