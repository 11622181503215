import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import information from "../../Assets/svg/information-circle.svg"
import arrow from "../../Assets/svg/arrow.svg"
import { connect } from 'react-redux';

class GeomapidVersion extends Component {
  render() {
    const { title, subtitle, auth, is_mobile } = this.props;
    const { version } = auth;

    return (
      <main className="auth_content_left w_350">
        <div>
          <label> {title} {" "}
            <button className="hover_bigger" onClick={this.toggle_version}>
              <img className='info_icon' src={information} alt="What's new" />
            </button>
          </label>
          {!is_mobile && <label>{subtitle}</label>}


          <section>
            <p>
              Version {version}
            </p>
            <label className="padding_x_5">{" . "}</label>
            <Link
              className="center_perfect bold text_white"
              to={{ pathname: "https://mapid.co.id" }}
              target="_blank" rel="noopener noreferer"
            >
              <u>About Us</u> <img src={arrow} alt="Landing Page" />
            </Link>
          </section>
        </div>
      </main>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
})(GeomapidVersion);