/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { io } from "socket.io-client";

/*PERSONAL COMPONENT*/
import MAP_EDITOR from "../../Components/editor_map/MAP_EDITOR";
import LOADING_PAGE from "../../Components/blocked_page/LOADING_PAGE";
import NO_LICENSE from "../../Components/blocked_page/NO_LICENSE";
import NO_ACCESS from "../../Components/blocked_page/NO_ACCESS";

/*REDUX FUNCTION*/
import {
  clearProject,
  get_project_only,
} from "../../App/actions/projectActions";
import {
  set_value_properties,
  get_detail_many_layer,
} from "../../App/actions/propertiesActions";
import {
  finishGenangan,
  getDetailLayerById,
} from "../../App/actions/layerNewActions";

import { clearLayer, realtime_update } from "../../App/actions/layerActions";
import { getUserPaymentList } from "../../App/actions/paymentActions";
import { getGroups, getGroupsProject } from "../../App/actions/groupActions";
import { set_value_user } from "../../App/actions/authActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";

/*PICTURE ASSET*/

/*GENERAL*/
import getStatusMember from "../../App/validation/get-status-member";
import dict from "../../Data/dict.json";
import decode_params_to_object from "../../App/validation/decode_params_to_object";

class PARENT_MAP_EDITOR extends Component {
  constructor(props) {
    super(props);
    this.socket = io("wss://socket.mapid.io");
  }

  componentDidMount() {
    this.props.verify_license_user();
    this.props.verify_license_group();

    const geo_project_link = this?.props?.match?.params?.geo_project_link;

    this.props.get_project_only(geo_project_link);
    this.props.getGroupsProject(geo_project_link);

    this.activate_socket_by_project(geo_project_link);
    this.props.set_value_properties({
      key: "folder_list_active",
      value: [],
    });
    this.props.getUserPaymentList();
    this.props.getGroups();

    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.properties.get_layer_list_status !==
        prevProps.properties.get_layer_list_status &&
      this.props.properties.get_layer_list_status === "done"
    ) {
      const { geo_project } = this.props.project;
      const { geo_layer_list } = this.props.layer;
      const params = window?.location?.search?.substring(1);

      let object_params = {};
      let layer_id;
      let folder_id;
      let sidebar_right_status = false;
      let layer_list_load_first = geo_project?.layer_list_load_first || [];
      layer_list_load_first = layer_list_load_first.filter((item) => !!item);

      if (params) {
        object_params = decode_params_to_object(params);
        layer_id = object_params?.layer_id;
        folder_id = object_params?.folder_id;
        sidebar_right_status =
          object_params?.sidebar_right_status === "true" ? true : false;
      }

      if (layer_id) {
        const folder_id = geo_layer_list.find(
          (item) => item.geo_layer._id === layer_id
        )?.folder;
        const body = {
          geo_layer_id: layer_id,
          geo_project_id: geo_project._id,
          flyStatus: true,
          folder_id,
        };
        this.props.getDetailLayerById(body);
      }

      if (folder_id) {
        const folder = geo_project.folders.find(
          (item) => item._id === folder_id
        );
        if (folder) {
          this.props.set_value_properties({
            key: "sidebar_right_status",
            value: true,
          });
          this.props.set_value_properties({
            key: "folder_kai_timeseries",
            value: folder,
          });
          const layer_list = geo_layer_list
            .filter(
              (element) =>
                element?.folder === folder_id &&
                !element?.geo_layer?.geojson?.features?.length
            )
            .map((item) => item?.geo_layer?._id);
          if (layer_list.length > 0) {
            const params = {
              layer_list,
              geo_project_id: geo_project._id,
              folder_id: folder_id,
            };
            this.props.get_detail_many_layer(params);
          }
        }
      }

      if (sidebar_right_status === true) {
        this.props.set_value_properties({
          key: "sidebar_right_status",
          value: true,
        });
      }

      if (layer_list_load_first.length > 0) {
        const params = {
          layer_list: layer_list_load_first,
          geo_project_id: geo_project._id,
          folder_id: "",
        };
        this.props.get_detail_many_layer(params);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearLayer();
    this.props.clearProject();
    this.props.finishGenangan();
    this.disconnect_socket();
  }

  activate_socket_by_project = (geo_project_id) => {
    this.socket.on(geo_project_id, (data) => {
      this.props.realtime_update(data);
    });
  };

  activate_socket_by_id_layer = (layer_id) => {
    this.socket.on(layer_id, (data) => {
      this.props.realtime_update(data);
    });
  };

  disconnect_socket = () => {
    this.socket.disconnect();
    this.socket.close();
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const geo_layer_link =
      this?.props?.match?.params?.geo_layer_link !== "no_layer"
        ? this?.props?.match?.params?.geo_layer_link
        : "";
    const mode = this?.props?.match?.params?.mode;
    const geo_project_link = this?.props?.match?.params?.geo_project_link;
    const { isAuthenticated } = this.props.auth;
    const { loadingProcess, itemLoading, chatStatus, realtimeStatus } =
      this.props.layer;
    const { geo_project } = this.props.project;
    const { user } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    let userCurrentStatus = "viewer";

    if (geo_project._id && geo_project?.groups?.length > 0) {
      userCurrentStatus = getStatusMember(geo_project.groups, user._id);
    } else if (geo_project?.user?._id === user?._id) {
      userCurrentStatus = "owner";
    }

    let content;

    /*
    Kemungkinan-kemungkinan state:

    //1. Loading
    //2. white list
    //3. data publik --> hanya berlaku di viewer, tidak dieditor
    //4. lolos authorisasi
    //5. lainnya belum login
    //6. lainnya sudah login    
    */

    if (
      loading_item_array.includes("verify_license_user") ||
      loading_item_array.includes("verify_license_group") ||
      loading_item_array.includes("get_project_only")
    ) {
      content = <LOADING_PAGE />;
    } else if (
      (!license_user_status.is_has_license &&
        !license_group_status.is_has_license) ||
      !isAuthenticated
    ) {
      content = <NO_LICENSE />;
    } else if (userCurrentStatus === "viewer") {
      content = <NO_ACCESS geo_project_link={geo_project_link} />;
    } else {
      content = (
        <MAP_EDITOR
          geo_project_link={geo_project_link}
          geo_layer_link={geo_layer_link}
          mode={mode}
        />
      );
    }

    let api_status = "";

    geo_project.api_status = api_status;

    let textLoading = "Saving";
    switch (itemLoading) {
      case "createLayer":
        textLoading = dict["Creating layer"][language];
        break;
      case "importLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "uploadLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "edit_general":
        textLoading = dict["Saving changes"][language];
        break;
      case "editAllFields":
        textLoading = dict["Saving changes"][language];
        break;
      case "editLayerForm":
        textLoading = dict["Saving changes"][language];
        break;
      case "pushFeature":
        textLoading = dict["Adding feature"][language];
        break;
      case "push_project_attach":
        textLoading = dict["Attaching layer"][language];
        break;
      case "delete_project_attach":
        textLoading = dict["Deleting attached layer"][language];
        break;
      case "edit_geometry":
        textLoading = dict["Saving geometry changes"][language];
        break;
      case "edit_properties":
        textLoading = dict["Saving attribute value"][language];
        break;
      case "delete_feature":
        textLoading = dict["Deleting"][language];
        break;
      case "hide_feature":
        textLoading = dict["Hiding feature"][language];
        break;
      case "push_field":
        textLoading = dict["Adding column"][language];
        break;
      case "editField":
        textLoading = dict["Saving column"][language];
        break;
      case "deleteField":
        textLoading = dict["Deleting column"][language];
        break;
      case "delete_layer":
        textLoading = dict["Deleting layer"][language];
        break;
      case "get_detail":
        textLoading = dict["Getting GIS data"][language];
        break;
      case "genangan":
        textLoading = "Menjalankan algoritma genangan";
        break;
      case "deleteFieldForm":
        textLoading = "Menghapus kolom tabel";
        break;
      case "move_layers_into_any_folder":
        textLoading = dict["Moving layers"][language];
        break;
      case "delete_layers_bulk":
        textLoading = dict["Deleting layers"][language];
        break;
      default:
        textLoading = itemLoading;
        break;
    }

    return (
      <div>
        {!!textLoading && !chatStatus && !realtimeStatus && loadingProcess && (
          <div
            style={{
              zIndex: "20",
              backgroundColor: "#244aa1",
              color: "#fff",
              position: "fixed",
              top: "0px",
              right: "0px",
              width: "100vw",
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "17px",
              fontWeight: "500",
            }}
          >
            {textLoading}
          </div>
        )}
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
  license_reducer: state.license_reducer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  getUserPaymentList,
  clearLayer,
  getGroups,
  getGroupsProject,
  finishGenangan,
  clearProject,
  set_value_user,
  getDetailLayerById,
  realtime_update,
  set_value_properties,
  get_detail_many_layer,
  verify_license_user,
  verify_license_group,
  get_project_only,
})(PARENT_MAP_EDITOR);
