/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/layerActions";
import {
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
} from "../../App/actions/insightActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

class InputAreaHirarki extends Component {
  state = {};

  componentDidMount() {
    this.props.get_list_all_provinsi();
  }

  on_change_general = (e) => {
    const { provinsi, kota } = this.props.map;

    const value = e.target.value;
    const name = e.target.name;
    this.props.set_value_layer(name, value);

    if (name === "provinsi") {
      this.props.set_value_layer({
        key: "kota",
        value: "",
      });
      this.props.set_value_layer({
        key: "kecamatan",
        value: "",
      });
      this.props.set_value_layer({
        key: "kelurahan",
        value: "",
      });
      this.props.get_list_all_kota_by_provinsi({
        provinsi: value,
      });
    } else if (name === "kota") {
      this.props.set_value_layer({
        key: "kecamatan",
        value: "",
      });
      this.props.set_value_layer({
        key: "kelurahan",
        value: "",
      });
      this.props.get_list_all_kecamatan_by_kota({
        provinsi,
        kota: value,
      });
    } else if (name === "kecamatan") {
      this.props.set_value_layer({
        key: "kelurahan",
        value: "",
      });
      this.props.get_list_all_kelurahan_by_kecamatan({
        provinsi,
        kota,
        kecamatan: value,
      });
    } else if (name === "kelurahan") {
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    let { provinsi, kota, kecamatan, kelurahan } = this.props.map;
    const {
      list_all_provinsi,
      list_all_kota,
      list_all_kecamatan,
      list_all_kelurahan,
    } = this.props.properties;

    //content

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_bold">{dict?.["Provinces"]?.[language]}</p>
          <select
            id="provinsi"
            name="provinsi"
            value={provinsi}
            onChange={this.on_change_general}
            className="input_new"
            data-mapid="selectProvince"
          >
            <option value="" hidden>
              {dict?.["Select provinces"]?.[language]}
            </option>
            {list_all_provinsi.map((item, idx) => {
              return (
                <option value={item.PROVINSI} key={idx}>
                  {item.PROVINSI}
                </option>
              );
            })}
          </select>
        </section>

        {provinsi && (
          <section className="margin_bottom">
            <p className="text_bold">{dict?.["Cities"]?.[language]}</p>
            <select
              id="kota"
              name="kota"
              value={kota}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectCities"
            >
              <option value="" hidden>
                {dict?.["Select cities"]?.[language]}
              </option>
              {list_all_kota.map((item, idx) => {
                return (
                  <option value={item?.["KABUPATEN ATAU KOTA"]} key={idx}>
                    {item?.["KABUPATEN ATAU KOTA"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}

        {kota && (
          <section className="margin_bottom">
            <p className="text_bold">Kecamatan</p>
            <select
              id="kecamatan"
              name="kecamatan"
              value={kecamatan}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectSubDistrict"
            >
              <option value="">Pilih Kecamatan</option>
              {list_all_kecamatan.map((item, idx) => {
                return (
                  <option value={item?.["KECAMATAN"]} key={idx}>
                    {item?.["KECAMATAN"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}

        {kecamatan && (
          <section className="margin_bottom">
            <p className="text_bold">Kelurahan</p>
            <select
              id="kelurahan"
              name="kelurahan"
              value={kelurahan}
              onChange={this.on_change_general}
              className="input_new"
              data-mapid="selectDistrict"
            >
              <option value="">Pilih kelurahan</option>
              {list_all_kelurahan.map((item, idx) => {
                return (
                  <option value={item?.["DESA ATAU KELURAHAN"]} key={idx}>
                    {item?.["DESA ATAU KELURAHAN"]}
                  </option>
                );
              })}
            </select>
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
})(InputAreaHirarki);
