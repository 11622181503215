import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import get_url_query from "../../App/validation/get_url_query";

const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.827153, -6.17511], // First point (Top-left corner)
            [106.829153, -6.17511], // Second point (Top-right corner)
            [106.829153, -6.17711], // Third point (Bottom-right corner)
            [106.827153, -6.17711], // Fourth point (Bottom-left corner)
            [106.827153, -6.17511], // Closing point (Same as first point)
          ],
        ],
      },
      properties: {
        name: "Java Island",
        color: "green",
        height: 50,
        opacity: 0.5,
      },
    },
  ],
};

const geojsonPolygon = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.82386504092693, -6.175643146538832],
            [106.82308097842457, -6.17602606478205],
            [106.82298469004667, -6.176928656687622],
            [106.82347988741571, -6.177817571454426],
            [106.82429146088481, -6.178214163715907],
            [106.82580456395857, -6.178132110168619],
            [106.82683622514554, -6.177844922654884],
            [106.82682246966328, -6.176791900437635],
            [106.82616220650311, -6.175807254391273],
            [106.82386504092693, -6.175643146538832],
          ],
        ],
      },
      properties: {
        name: "Poygon Island",
        tinggi_meter: 100,
      },
    },
  ],
};

class LIBRE_DIDIT extends Component {
  state = {};

  componentDidMount() {
    const mode = get_url_query("mode");
    if (mode === "didit") {
      this.on_fly();
      this.on_render_content();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      const mode = get_url_query("mode");
      if (mode === "didit") {
        this.on_fly();
        this.on_render_content();
      }
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map = "libre_didit";
    if (!map_object.getSource(id_map)) {
      map_object.addSource(id_map, {
        type: "geojson",
        data: geojson,
      });
    } else {
      map_object.getSource(id_map).setData(geojson);
    }

    const id_map1 = "libre_didit1";
    if (!map_object.getSource(id_map1)) {
      map_object.addSource(id_map1, {
        type: "geojson",
        data: geojsonPolygon,
      });
    } else {
      map_object.getSource(id_map1).setData(geojsonPolygon);
    }

    const loopPaints = {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
      // "fill-extrusion-opacity": ["get", "opacity"],
    };

    if (!map_object.getLayer(id_map)) {
      map_object.addLayer({
        id: id_map,
        source: id_map,
        type: "fill-extrusion",
        paint: loopPaints,
        layout: { visibility: "visible" },
      });
    } else {
      for (const item of loopPaints) {
        map_object.setPaintProperty(id_map, item, loopPaints[item]);
      }
    }

    if (!map_object.getLayer(id_map1)) {
      map_object.addLayer({
        id: id_map1,
        source: id_map1,
        type: "fill-extrusion",
        paint: {
          // "fill-color": "red",
          // "fill-opacity": 0.3,
          // "fill-outline-color": "#000",
          "fill-extrusion-color": "blue",
          "fill-extrusion-height": 50,
          "fill-extrusion-opacity": 0.5,
        },
        layout: { visibility: "visible" },
      });
    } else {
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_DIDIT);
