/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import SINI_DOWNLOAD_UNIVERSAL from "./SINI_DOWNLOAD_UNIVERSAL";
import SINI_POLYGON_CONFIG from "./SINI_POLYGON_CONFIG";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import manifest_demography from "../../Data/manifest_demography.json";
import sort_array from "../../App/validation/sort_array";
import st_colors from "../../Data/st_colors.json";

/*NON IMPORT*/
const animation_duration = 500;
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

class SINI_DEMOGRAPHY_FULL extends Component {
  state = {
    begin_at_zero: true,
    anchor: null,
    modal_download: false,
    modal_layer_config: false,
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_download = (e) => {
    this.setState({ modal_download: !this.state.modal_download });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  toggle_zoom_chart = () => {
    this.setState({
      begin_at_zero: !this.state.begin_at_zero,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { begin_at_zero, modal_download, modal_layer_config, anchor } =
      this.state;

    //global props
    const {
      sini_v2_list,
      request_id_active,
      ai_parent_detail_list,
      is_show_download_button,
    } = this.props.sini;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const properties = sini_object?.demography_one?.properties || {};

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POLYGON_CONFIG />
        </div>
      </Menu>
    );

    const modal_download_content = modal_download && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_download}
        onClose={this.toggle_download}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_DOWNLOAD_UNIVERSAL download_topic="demography_one" />
        </div>
      </Menu>
    );

    return (
      <main className="sini_full_parent">
        {modal_download_content}
        {modal_layer_config_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                  data-mapid="clickBack"
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <p className="badge background_green margin_bottom margin_right">
                    {dict?.["Demographics"]?.[language]}
                  </p>
                </td>
                <td className="text_left">
                  <p
                    onClick={this.toggle_layer_config}
                    className="button button_white outline_black margin_bottom margin_right"
                  >
                    Visual setting
                  </p>
                </td>
                {is_show_download_button && (
                  <td>
                    <button
                      className="badge background_green margin_bottom"
                      onClick={this.toggle_download}
                    >
                      {dict?.["Download"]?.[language]}
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </section>

        <section className="sini_full_scroll_area">
          <section className="container_light background_white margin_bottom">
            <section className="margin_bottom">
              <p className="text_small">Desa</p>
              <p className="text_bold">{properties?.["DESA ATAU KELURAHAN"]}</p>
            </section>
            <section className="margin_bottom">
              <p className="text_small">Kota</p>
              <p className="text_bold">{properties?.["KABUPATEN ATAU KOTA"]}</p>
            </section>
            <section className="margin_bottom">
              <p className="text_small">Jumlah penduduk</p>
              <p className="text_bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(properties?.["JUMLAH PENDUDUK"])}
              </p>
            </section>
            <section className="margin_bottom">
              <p className="text_small">Jumlah KK</p>
              <p className="text_bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(properties?.["JUMLAH KK"])}
              </p>
            </section>
          </section>
          {manifest_demography.map((item, idx) => {
            const { chart_type, child } = item;
            let chart_content;
            if (chart_type === "line" || chart_type === "bar") {
              const label_array_line = child.map((item) => {
                return item[language];
              });
              const number_array_line = child.map((item) => {
                let number = Number(properties?.[item?.key]);
                if (isNaN(number)) number = 0;
                return number;
              });
              const data = {
                labels: label_array_line,
                datasets: [
                  {
                    label: child[language],
                    data: number_array_line,
                    fill: false,
                    backgroundColor: "#21a5f6",
                    borderColor: "#1a649d",
                    hoverBackgroundColor: "#1a649d",
                    hoverBorderColor: "#d1f962",
                  },
                ],
              };
              const options_line = {
                scales: {
                  y: {
                    beginAtZero: begin_at_zero,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                animation: {
                  duration: animation_duration,
                },
              };
              chart_content = (
                <div>
                  <Chart type={chart_type} data={data} options={options_line} />
                  {chart_type === "line" && (
                    <button
                      className="button_small background_grey"
                      onClick={this.toggle_zoom_chart}
                    >
                      {begin_at_zero ? "Zoom slope" : "Lihat sumbu 0"}
                    </button>
                  )}
                </div>
              );
            } else if (chart_type === "pie") {
              let array_of_object_pie = child.map((item) => {
                let number = Number(properties?.[item?.key]);
                if (isNaN(number)) number = 0;
                return {
                  label: item[language],
                  number,
                };
              });
              array_of_object_pie = sort_array(
                array_of_object_pie,
                "number",
                false
              );
              const label_array_pie = array_of_object_pie.map((item) => {
                return item.label;
              });
              const number_array_pie = array_of_object_pie.map((item) => {
                return item.number;
              });
              const color_array = number_array_pie.map((item, idx) => {
                const index =
                  idx < st_colors.length - 1 ? idx : st_colors.length % idx;
                return st_colors[index];
              });
              const data = {
                labels: label_array_pie,
                datasets: [
                  {
                    label: item[language],
                    data: number_array_pie,
                    fill: false,
                    backgroundColor: color_array,
                    borderColor: "rgba(75, 192, 192, 0.2)",
                  },
                ],
              };
              const options_pie = {
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                animation: {
                  duration: animation_duration,
                },
              };
              chart_content = (
                <main className="parent_half">
                  <section className="left_half pie_chart">
                    <Chart type="pie" data={data} options={options_pie} />
                  </section>
                  <section className="right_half">
                    <table className="full_width text_small top_aligned_cell hover_grey">
                      <tbody>
                        {array_of_object_pie.map(
                          (chart_element, index_table_chart) => {
                            return (
                              <tr key={index_table_chart}>
                                <td>{index_table_chart + 1}</td>
                                <td>
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "5px",
                                      backgroundColor:
                                        color_array[index_table_chart],
                                    }}
                                  />
                                </td>
                                <td>{chart_element.label}</td>
                                <td>{chart_element.number}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </section>
                </main>
              );
            } else {
              chart_content = (
                <div>
                  {child.map((child_item, index_child_item) => {
                    let value;
                    if (child_item.type === "interger") {
                      value = parseInt(properties?.[child_item?.key]);
                    } else if (child_item.type === "float") {
                      const decimal =
                        child_item.decimal !== undefined
                          ? child_item.decimal
                          : 2;
                      value = parseFloat(properties?.[child_item?.key]).toFixed(
                        decimal
                      );
                    } else {
                      value = properties?.[child_item?.key];
                    }
                    return (
                      <div
                        key={index_child_item}
                        className="margin_bottom_extra"
                      >
                        <p className="text_small">{child_item[language]}</p>
                        <p className="text_bold">{value}</p>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div key={idx} className="margin_bottom_extra">
                <p className="button_big background_light_grey margin_bottom">
                  {item[language]}
                </p>
                {chart_content}
              </div>
            );
          })}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_sini,
})(SINI_DEMOGRAPHY_FULL);
