import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import maplibregl from "maplibre-gl";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import get_url_query from "../../App/validation/get_url_query";

const geojson_fill = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.85672648673267, -6.19440129078356],
            [106.85298638111101, -6.196083412707594],
            [106.85013688183795, -6.198827707834454],
            [106.85222961136321, -6.20303293532514],
            [106.85516811055288, -6.203829438832486],
            [106.85921885910608, -6.20405014530219],
            [106.8607765750429, -6.20258943725959],
            [106.86122229332005, -6.1988719039390645],
            [106.85619282838866, -6.198650618834833],
            [106.85672648673267, -6.19440129078356],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        color: "red",
        height: 100,
        base: 0,
        opacity: 1,
      },
    },
  ],
};

const paint_object_fill = {
  "fill-color": "red",
  "fill-opacity": 0.3,
  "fill-outline-color": "#000",
};

const geojson_fill_ext = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.83906022426328, -6.173820408611675],
            [106.83810200843658, -6.181343632740379],
            [106.8443498821448, -6.1827908067498925],
            [106.84706070867219, -6.179810908690698],
            [106.84740383561791, -6.176854464448979],
            [106.84607656418319, -6.174270801904768],
            [106.8432849419205, -6.17135380122086],
            [106.83906022426328, -6.173820408611675],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        color: "#ffffff",
        height: 100,
        base: 200,
        opacity: 0.5,
      },
    },
  ],
};

// const paint_object_ext = {
//   "fill-extrusion-color": "blue",
//   "fill-extrusion-height": 100,
//   "fill-extrusion-base": 0,
//   "fill-extrusion-opacity": 1,
// };

const paint_object_ext = {
  "fill-extrusion-color": ["get", "color"],
  "fill-extrusion-height": ["get", "height"],
  "fill-extrusion-base": ["get", "base"],
  "fill-extrusion-opacity": 1,
};

class LIBRE_MACHRUS extends Component {
  state = {};

  componentDidMount() {
    const mode = get_url_query("mode");
    if (mode === "machrus") {
      this.on_render_content();
      this.on_fly();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.layer.map_object !== prevProps.layer.map_object ||
      this.props.properties.basemap_used !== prevProps.properties.basemap_used
    ) {
      const mode = get_url_query("mode");
      if (mode === "machrus") {
        this.on_render_content();
        this.on_fly();
      }
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map_fill = "libre_machrus_fill";
    if (!map_object.getSource(id_map_fill)) {
      map_object.addSource(id_map_fill, {
        type: "geojson",
        data: geojson_fill,
      });
    } else {
      map_object.getSource(id_map_fill).setData(geojson_fill);
    }
    if (!map_object.getLayer(id_map_fill)) {
      map_object.addLayer({
        id: id_map_fill,
        source: id_map_fill,
        type: "fill",
        paint: paint_object_fill,
        layout: { visibility: "visible" },
      });
    } else {
      for (const key in paint_object_fill) {
        map_object.setPaintProperty(id_map_fill, key, paint_object_fill[key]);
      }
    }

    const id_map_ext = "libre_machrus_ext";
    if (!map_object.getSource(id_map_ext)) {
      map_object.addSource(id_map_ext, {
        type: "geojson",
        data: geojson_fill_ext,
      });
    } else {
      map_object.getSource(id_map_ext).setData(geojson_fill_ext);
    }
    if (!map_object.getLayer(id_map_ext)) {
      map_object.addLayer({
        id: id_map_ext,
        source: id_map_ext,
        type: "fill-extrusion",
        paint: paint_object_ext,
        layout: { visibility: "visible" },
      });
    } else {
      for (const key in paint_object_ext) {
        map_object.setPaintProperty(id_map_ext, key, paint_object_ext[key]);
      }
    }

    // Adding 3D GLTF Model Layer
    this.addGLTFLayer(map_object);
  };

  addGLTFLayer = (map_object) => {
    const id_3d_model = "3d-model-layer";

    // Check if the 3D model layer already exists
    if (!map_object.getLayer(id_3d_model)) {
      const modelOrigin = [106.82650240698052, -6.176826213922325]; // Change to your desired coordinates
      const modelAltitude = 0;
      const modelRotate = [Math.PI / 2, 0, 0];

      const modelAsMercatorCoordinate =
        maplibregl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

      // transformation parameters to position, rotate and scale the 3D model onto the map
      const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };

      // configuration of the custom layer for a 3D model per the CustomLayerInterface
      const customLayer = {
        id: "3d-model",
        type: "custom",
        renderingMode: "3d",
        onAdd(map, gl) {
          this.camera = new THREE.Camera();
          this.scene = new THREE.Scene();

          // create two three.js lights to illuminate the model
          const directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -70, 100).normalize();
          this.scene.add(directionalLight);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff);
          directionalLight2.position.set(0, 70, 100).normalize();
          this.scene.add(directionalLight2);

          // use the three.js GLTF loader to add the 3D model to the three.js scene
          const loader = new GLTFLoader();
          loader.load(
            "https://maplibre.org/maplibre-gl-js/docs/assets/34M_17/34M_17.gltf",
            (gltf) => {
              this.scene.add(gltf.scene);
            }
          );
          this.map = map;

          // use the MapLibre GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          });

          this.renderer.autoClear = false;
        },
        render(gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );

          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        },
      };

      map_object.addLayer(customLayer);
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_fill);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_MACHRUS);
