/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import maplibregl from "maplibre-gl";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*GENERAL FUNCTION & DATA*/
import generate_color_map from "../../App/validation/generate_color_map";
import { generatePopupContentDOM } from "../libre_popup/popup_geo";

/*NON IMPORT*/

class BI_LAYER extends Component {
  state = {};

  componentDidMount() {
    this.on_render();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.bi.trigger_rerender !== prevProps.bi.trigger_rerender ||
        this.props.layer.map_object !== prevProps.layer.map_object) &&
      this.props.layer.map_object
    ) {
      this.on_render();
    }
    if (this.props.bi.trigger_fly_map !== prevProps.bi.trigger_fly_map) {
      this.on_fly_layer();
    }
  }

  on_fly_layer = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;
    const { map_object } = this.props.layer;
    const geo_layer_list = this.get_geo_layers(
      project_list,
      bi_object,
      project_object_selected,
      layer_id
    );
    let features = this.get_features_from_layers(geo_layer_list);
    const geojson = {
      type: "FeatureCollection",
      features: features,
    };
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    if (map_object) {
      map_object.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 10, bottom: 10, left: 10, right: 10 },
          maxZoom: this.props.layer.max_zoom,
          duration: 1000,
        }
      );
    }
  };

  on_render = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;
    const { map_object, layer_apply_color } = this.props.layer;

    if (map_object) {
      const geo_layer_list = this.get_geo_layers(
        project_list,
        bi_object,
        project_object_selected,
        layer_id
      );

      geo_layer_list.forEach((data) => {
        const _id = data?.geo_layer?._id;
        const fields = data?.geo_layer?.fields || [];
        const visibility = "visible";
        const apply_color_id = layer_apply_color.find(
          (item) => item.layer_id === _id
        );
        const paint_data = this.get_paint_object({
          data,
          apply_color_id,
          style_array: data?.geo_layer?.style_array,
          default_style_key: data?.geo_layer?.default_style_key,
        });
        const paint_object = paint_data.paint_object;
        const paint_object_line_polygon = paint_data.paint_object_line_polygon;
        const geojson = {
          type: "FeatureCollection",
          features: data?.geo_layer?.geojson?.features || [],
        };
        if (!map_object.getSource(_id)) {
          map_object.addSource(_id, {
            type: "geojson",
            data: geojson,
          });
        } else {
          map_object.getSource(_id).setData(geojson);
        }
        const shape_type = this.get_layer_type(data?.geo_layer?.type);

        if (!map_object.getLayer(_id)) {
          map_object.addLayer({
            id: _id,
            source: _id,
            type: shape_type,
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
          if (shape_type === "fill") {
            map_object.addLayer({
              id: _id + "_line_polygon",
              source: _id,
              type: "line",
              paint: paint_object_line_polygon,
              layout: {
                visibility: visibility,
              },
            });
          }
        } else {
          map_object.setLayoutProperty(_id, "visibility", visibility);
          Object.keys(paint_object).forEach((property) => {
            map_object.setPaintProperty(_id, property, paint_object[property]);
          });
          if (shape_type === "fill") {
            map_object.setLayoutProperty(
              _id + "_line_polygon",
              "visibility",
              visibility
            );
            Object.keys(paint_object_line_polygon).forEach((property) => {
              map_object.setPaintProperty(
                _id + "_line_polygon",
                property,
                paint_object_line_polygon[property]
              );
            });
          }
        }

        //POP UP
        this.generate_pop_up(_id, fields);
      });
    }
  };

  generate_pop_up = (_id, fields) => {
    const { map_object, geometryStatus, layer_id } = this.props.layer;

    if (map_object) {
      // Check if the handler is already stored, remove it
      if (this?.state?.popup_handlers?.[_id]) {
        map_object.off("click", _id, this.state.popup_handlers[_id]);
        map_object.off("touchstart", _id, this.state.popup_handlers[_id]); // Remove touchstart handler too
      }

      if (!geometryStatus || !layer_id) {
        // Create a new handler
        const click_handler = (event) => {
          const feature = event?.features?.[0];
          if (feature) {
            const properties = feature?.properties || {};
            const geometry = feature?.geometry;
            const type = geometry?.type;
            let longitude, latitude;

            if (type === "Point") {
              longitude = geometry?.coordinates?.[0];
              latitude = geometry?.coordinates?.[1];
            } else if (type === "LineString") {
              const length_km = calculate_length(feature, {
                units: "kilometers",
              }).toFixed(2);
              const center_length = length_km / 2;
              let feature_center = along(feature, center_length, {
                units: "kilometers",
              });
              longitude = feature_center?.geometry?.coordinates?.[0];
              latitude = feature_center?.geometry?.coordinates?.[1];
            } else {
              const geojson = {
                type: "FeatureCollection",
                features: [feature],
              };
              const feature_center = centroid(geojson);
              longitude = feature_center?.geometry?.coordinates?.[0];
              latitude = feature_center?.geometry?.coordinates?.[1];
            }

            const { container, close_button } = generatePopupContentDOM(
              properties,
              fields
            );
            const popup = new maplibregl.Popup({ closeButton: false }) // Disable maplibre's default close button
              .setLngLat([longitude, latitude])
              .setDOMContent(container)
              .addTo(map_object);

            // Add a listener for the custom close button
            close_button.addEventListener("click", () => {
              popup.remove(); // Manually close the popup when the button is clicked
            });

            const popup_content = container.parentElement; // Access the popup content div
            popup_content.style.borderRadius = "15px";
            popup_content.style.margin = "0";
            popup_content.style.padding = "10px";
            popup_content.style.overflow = "hidden";
          }
        };

        // Save the handler in state
        this.setState((prevState) => ({
          popup_handlers: { ...prevState.popup_handlers, [_id]: click_handler },
        }));

        // Attach both click and touchstart events
        map_object.on("click", _id, click_handler); // For desktop devices
        map_object.on("touchstart", _id, click_handler); // For touchscreen devices
      }
    } else {
      console.log("no map_object=", map_object);
    }
  };

  cleanup_event_listerners = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      const { popup_handlers } = this.state;
      //remove all event listeners for each layer
      Object.keys(popup_handlers).forEach((layer_id) => {
        map_object.off("click", layer_id, popup_handlers[layer_id]);
      });
      //reset state
      this.setState({ popup_handlers: {} });
    }
  };

  get_geo_layers = (
    project_list,
    bi_object,
    project_object_selected,
    layer_id
  ) => {
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];
    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });
    if (layer_id) {
      geo_layer_list = geo_layer_list.filter(
        (item) => item?.geo_layer?._id === layer_id
      );
    }
    return geo_layer_list;
  };

  get_features_from_layers = (geo_layer_list) => {
    let features = [];
    geo_layer_list.forEach((layer) => {
      let features_inside = layer?.geo_layer?.geojson?.features || [];
      if (features_inside.length > 0) {
        features = [...features, ...features_inside];
      }
    });
    return features;
  };

  get_layer_type = (type) => {
    switch (type) {
      case "Point":
      case "MultiPoint":
        return "circle";
      case "LineString":
      case "MultiLineString":
        return "line";
      case "Polygon":
      case "MultiPolygon":
        return "fill";
      default:
        return "circle";
    }
  };

  get_paint_object = ({
    data,
    apply_color_id,
    style_array,
    default_style_key,
  }) => {
    let shape_type;
    let paint_object = {};
    let paint_object_line_polygon = {};
    const type = data?.geo_layer?.type;
    const properties_raw = data?.geo_layer?.properties;

    switch (type) {
      case "Point":
      case "MultiPoint":
        shape_type = "circle";
        paint_object = {
          "circle-color": "#1a649d",
          "circle-opacity": 1,
          "circle-radius": 3,
          "circle-stroke-color": "#fff",
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 1,
          "circle-blur": 0,
          "circle-pitch-alignment": "map",
          "circle-pitch-scale": "map",
          "circle-translate": [0, 0],
          "circle-translate-anchor": "map",
        };
        break;
      case "LineString":
      case "MultiLineString":
        shape_type = "line";
        paint_object = {
          "line-color": "#1a649d",
          "line-width": 2,
          "line-opacity": 1,
        };
        break;
      case "Polygon":
      case "MultiPolygon":
        shape_type = "fill";
        paint_object = {
          "fill-color": "#1a649d",
          "fill-opacity": 0.7,
        };
        paint_object_line_polygon = {
          "line-color": "#000",
          "line-width": 1,
          "line-opacity": 1,
        };
        break;
      default:
        shape_type = "circle";
        paint_object = {
          "circle-color": "#1a649d",
          "circle-opacity": 1,
          "circle-radius": 3,
          "circle-stroke-color": "#fff",
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 1,
          "circle-blur": 0,
          "circle-pitch-alignment": "map",
          "circle-pitch-scale": "map",
          "circle-translate": [0, 0],
          "circle-translate-anchor": "map",
        };
        break;
    }
    let circle_radius = Number(
      properties_raw?.find((d) => d?.key === "circle_radius")?.defaultValue
    );
    let opacity = Number(
      properties_raw?.find((d) => d?.key === "opacity")?.defaultValue
    );
    let outline, stroke;
    let case_color_style = 0;

    // CASE 1: Apply color from the frontend
    if (apply_color_id) {
      case_color_style = 1;
      let apply_value = apply_color_id?.config?.array_color?.labels || [];
      let apply_color =
        apply_color_id?.config?.array_color?.datasets?.[0]?.backgroundColor;
      const array_apply_color = generate_color_map(
        apply_value,
        apply_color,
        apply_color_id?.config?.field_key
      );

      if (type === "Point" || type === "MultiPoint") {
        paint_object = {
          "circle-color": array_apply_color,
          "circle-radius": circle_radius ? circle_radius : 7,
          "circle-stroke-width": stroke ? stroke : 2,
          "circle-stroke-color": outline ? outline : "#fff",
        };
      } else if (type === "LineString" || type === "MultiLineString") {
        paint_object = {
          "line-color": array_apply_color,
          "line-width": 3,
          "line-opacity": opacity ? opacity : 0.5,
        };
      } else if (type === "Polygon" || type === "MultiPolygon") {
        paint_object = {
          "fill-color": array_apply_color,
          "fill-opacity": 0.4,
          "fill-outline-color": "transparent",
        };
      }
    }
    // CASE 3: Apply saved styles from the database
    else if (style_array.length > 0) {
      case_color_style = 3;
      const paint_object_db =
        style_array.find((item) => item?.key === default_style_key)
          ?.paint_object || {};

      if (shape_type === "fill") {
        paint_object = {
          "fill-color": paint_object_db["fill-color"],
          "fill-opacity": paint_object_db["fill-opacity"],
          "fill-outline-color": "transparent",
        };
        paint_object_line_polygon = {
          "line-color": paint_object_db["line-color"],
          "line-width": paint_object_db["line-width"],
          "line-opacity": paint_object_db["line-opacity"],
        };
      } else if (type === "line") {
        paint_object = {
          "line-color": paint_object_db["line-color"],
          "line-width": paint_object_db["line-width"],
          "line-opacity": paint_object_db["line-opacity"],
        };
      } else {
        paint_object = paint_object_db;
      }
    }
    return { paint_object, paint_object_line_polygon, case_color_style };
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(BI_LAYER);
