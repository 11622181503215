/*LIBRARY MODULE*/
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Map } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

/*PERSONAL COMPONENT*/
import MAP_CONTROL_DRAW from "../libre_draw/MAP_CONTROL_DRAW";
import MAP_CONTROL_BASIC from "../libre_draw/MAP_CONTROL_BASIC";
import DRAW_GEO from "../libre_draw/DRAW_GEO";
import Legend from "../map_legend/Legend";
import POPUP_EDITOR from "../libre_popup/POPUP_EDITOR";
import LAYER_FEATURE_SELECTED from "../libre_layer/LAYER_FEATURE_SELECTED";

import LAYER_GEO from "../libre_layer/LAYER_GEO";
import MARKER_GEO from "../libre_layer/MARKER_GEO";
import LAYER_HEATMAP from "../libre_layer/LAYER_HEATMAP";
import LAYER_GPS from "../libre_layer/LAYER_GPS";
import LAYER_TOOLBOX from "../libre_layer/LAYER_TOOLBOX";
import LAYER_INSIGHT from "../libre_layer/LAYER_INSIGHT";
import LAYER_GENANGAN from "../libre_layer/LAYER_GENANGAN";

//POINT
import LAYER_S_MARKER from "../libre_layer_sini/LAYER_S_MARKER";
import LAYER_S_CENTER from "../libre_layer_sini/LAYER_S_CENTER";
import LAYER_S_POI from "../libre_layer_sini/LAYER_S_POI";
import LAYER_S_POI_SURVEY_1 from "../libre_layer_sini/LAYER_S_POI_SURVEY_1";

//POLYGON
import LAYER_S_DEMOGRAPHY_ONE from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_ONE";
import LAYER_S_DEMOGRAPHY_NEIGHBOR from "../libre_layer_sini/LAYER_S_DEMOGRAPHY_NEIGHBOR";
import LAYER_S_ISOCHRONE from "../libre_layer_sini/LAYER_S_ISOCHRONE";
import LAYER_S_FLOOD_NEIGHBOR from "../libre_layer_sini/LAYER_S_FLOOD_NEIGHBOR";
import LAYER_S_FLOOD from "../libre_layer_sini/LAYER_S_FLOOD";
import LAYER_S_LANDSLIDE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDSLIDE_NEIGHBOR";
import LAYER_S_LANDSLIDE from "../libre_layer_sini/LAYER_S_LANDSLIDE";
import LAYER_S_LANDVALUE from "../libre_layer_sini/LAYER_S_LANDVALUE";
import LAYER_S_LANDVALUE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDVALUE_NEIGHBOR";
import LAYER_S_LANDZONE_NEIGHBOR from "../libre_layer_sini/LAYER_S_LANDZONE_NEIGHBOR";
import LAYER_S_LANDZONE from "../libre_layer_sini/LAYER_S_LANDZONE";
import LAYER_S_RDTR_NEIGHBOR from "../libre_layer_sini/LAYER_S_RDTR_NEIGHBOR";
import LAYER_S_RDTR_ONE from "../libre_layer_sini/LAYER_S_RDTR_ONE";
import LAYER_S_TSUNAMI_NEIGHBOR from "../libre_layer_sini/LAYER_S_TSUNAMI_NEIGHBOR";
import LAYER_S_TSUNAMI from "../libre_layer_sini/LAYER_S_TSUNAMI";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_sini } from "../../App/actions/sini_v2";

import LIBRE_MACHRUS from "../libre_latihan/LIBRE_MACHRUS";
import LIBRE_EGI from "../libre_latihan/LIBRE_EGI";
import LIBRE_FATHUR from "../libre_latihan/LIBRE_FATHUR";
import LIBRE_DIDIT from "../libre_latihan/LIBRE_DIDIT";
import LIBRE_NETA from "../libre_latihan/LIBRE_NETA";

/*GENERAL FUNCTION & DATA*/

const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;

const MAP_COMPONENT = ({
  set_value_layer,
  set_value_sini,
  layer,
  is_editable,
}) => {
  const handlers = useRef([]); // Define handlers with useRef
  const mapObjectRef = useRef(null); // To keep track of map object

  useEffect(() => {
    const map_object = new Map({
      container: "map_object",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [109.13270221994014, -6.480303321757276],
      zoom: 7,
      maxPitch: 85,
      projection: "globe",
    });

    mapObjectRef.current = map_object; // Save map_object in the ref
    set_value_layer({
      key: "map_object",
      value: map_object,
    });

    return () => {
      // Clean up on unmount
      if (handlers.current.length > 0) {
        handlers.current.forEach((handler) => {
          if (typeof handler.remove === "function") {
            handler.remove();
          }
        });
        handlers.current = []; // Reset handlers
      }

      if (
        mapObjectRef.current &&
        typeof mapObjectRef.current.remove === "function"
      ) {
        mapObjectRef.current.remove();
      }

      set_value_layer({
        key: "map_object",
        value: null,
      });

      set_value_sini({
        key: "sini_v2_list",
        value: [],
      });
      set_value_sini({
        key: "ai_parent_detail_list",
        value: [],
      });
      set_value_sini({
        key: "ai_parent_list",
        value: [],
      });
    };
  }, [set_value_layer, set_value_sini]);

  const {
    modal_edit_feature_properties,
    latitude_selected,
    longitude_selected,
    fields_selected,
    properties_selected,
    type_2_selected,
    feature_key_selected,
    geo_layer_selected,
    genangan_cm_selected,
  } = layer;

  return (
    <>
      <main
        id="map_object"
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      <MAP_CONTROL_DRAW />
      <MAP_CONTROL_BASIC />
      <DRAW_GEO />
      <MARKER_GEO />
      <LAYER_GEO />
      <LAYER_HEATMAP />
      <LAYER_TOOLBOX />
      <LAYER_GPS />
      <LAYER_INSIGHT />
      <LAYER_GENANGAN />
      <LAYER_S_MARKER />
      <LAYER_S_CENTER />
      <LAYER_S_ISOCHRONE />
      <LAYER_S_POI />
      <LAYER_S_DEMOGRAPHY_ONE />
      <LAYER_S_DEMOGRAPHY_NEIGHBOR />
      <LAYER_S_LANDVALUE />
      <LAYER_S_LANDVALUE_NEIGHBOR />
      <LAYER_S_LANDZONE />
      <LAYER_S_LANDZONE_NEIGHBOR />
      <LAYER_S_RDTR_ONE />
      <LAYER_S_RDTR_NEIGHBOR />
      <LAYER_S_POI_SURVEY_1 />
      <LAYER_S_FLOOD />
      <LAYER_S_FLOOD_NEIGHBOR />
      <LAYER_S_LANDSLIDE />
      <LAYER_S_LANDSLIDE_NEIGHBOR />
      <LAYER_S_TSUNAMI />
      <LAYER_S_TSUNAMI_NEIGHBOR />
      <LAYER_FEATURE_SELECTED />
      <Legend />
      {modal_edit_feature_properties && (
        <POPUP_EDITOR
          latitude_selected={latitude_selected}
          longitude_selected={longitude_selected}
          fields_selected={fields_selected}
          properties_selected={properties_selected} //properties dari feature yang dipilih
          type_2_selected={type_2_selected}
          feature_key_selected={feature_key_selected}
          genangan_cm_selected={genangan_cm_selected}
          geo_layer_selected={geo_layer_selected}
          is_editable={is_editable}
        />
      )}

      <LIBRE_MACHRUS />
      <LIBRE_EGI />
      <LIBRE_FATHUR />
      <LIBRE_DIDIT />
      <LIBRE_NETA />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, { set_value_layer, set_value_sini })(
  MAP_COMPONENT
);
