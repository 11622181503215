/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
// import maplibregl from "maplibre-gl";
// import centroid from "@turf/centroid";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*GENERAL FUNCTION & DATA*/
import colors_layer_flood from "../../Data/colors_layer_flood.json";
// import colors_flood from "../../Data/colors_flood.json";

/*NON IMPORT*/

class LAYER_S_LANDSLIDE_NEIGHBOR extends Component {
  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const { map_object } = this.props.layer;

    if (
      map_object &&
      (state_update_after !== state_update_before ||
        prevProps.layer?.map_object !== map_object)
    ) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
    } = this.props.sini;
    const { map_object } = this.props.layer;

    const visibility = sini_map_show.includes("landslide_neighbor")
      ? "visible"
      : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];

    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const landslide_neighbor = item?.landslide_neighbor || [];
        const geojson_landslide_neighbor = {
          type: "FeatureCollection",
          features: landslide_neighbor,
        };
        const layer_id_fill = `geojson_landslide_neighbor_${request_id}`;
        const layer_id_line = `geojson_landslide_neighbor_line_${request_id}`;
        if (!map_object.getSource(layer_id_fill)) {
          map_object.addSource(layer_id_fill, {
            type: "geojson",
            data: geojson_landslide_neighbor,
          });
        } else {
          map_object
            .getSource(layer_id_fill)
            .setData(geojson_landslide_neighbor);
        }
        if (!map_object.getLayer(layer_id_fill)) {
          map_object.addLayer({
            id: layer_id_fill,
            source: layer_id_fill,
            type: "fill",
            paint: {
              "fill-color": colors_layer_flood,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_fill, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_fill,
            "fill-opacity",
            Number(sini_polygon_fill_opacity)
          );
        }
        if (!map_object.getLayer(layer_id_line)) {
          map_object.addLayer({
            id: layer_id_line,
            source: layer_id_fill,
            type: "line",
            paint: {
              "line-color": colors_layer_flood,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            },
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id_line, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_line,
            "line-width",
            Number(sini_polygon_line_width)
          );
        }
      });
    }
  };

  render() {
    return null; // No UI elements, just managing map layers and labels
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LAYER_S_LANDSLIDE_NEIGHBOR);
