import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import {
  setCurrentUser,
  getUserFromToken,
  setPathname,
  getTutorialList,
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
  logoutUser,
} from "../App/actions/authActions";
import { getUserPaymentList } from "../App/actions/paymentActions";
import { get_admin_information_list } from "../App/actions/propertiesActions";
import {
  verify_license_group,
  verify_license_user,
} from "../App/actions/license_actions";

import history from "../App/actions/history";

import Navigation from "../Components/layout/Navigation";
import ModalTutorial from "../Components/layout/ModalTutorial";
import MODAL_SHARE_LINK from "../Components/map_reusable/MODAL_SHARE_LINK";

import { PrivateRoute } from "../App/validation";

import "../Style/App.css";
import "../Style/Rich.css";
import "../Style/SingleStyle.css";
import "../Style/bi.css";
import "../Style/map_control.css";
import "../Style/map_control_draw.css";

import * as Page from "../Pages";
import SNACKBAR_PARENT from "../Components/common_modal/SNACKBAR_PARENT";

const token_mapid = localStorage.token_mapid;
if (token_mapid && token_mapid !== "undefined" && token_mapid !== undefined) {
  store.dispatch(
    setCurrentUser(JSON.parse(localStorage.getItem("mapid_user")))
  );
  store.dispatch(getUserFromToken(token_mapid));
  store.dispatch(getUserPaymentList());
  store.dispatch(get_quota_access());
  store.dispatch(get_quota_access_sini_ai());
  store.dispatch(get_quota_ai_chat());
  store.dispatch(verify_license_group());
  store.dispatch(verify_license_user());
} else {
  store.dispatch(logoutUser());
}

class App extends Component {
  componentDidMount() {
    store.dispatch(getTutorialList());
    store.dispatch(get_admin_information_list());
    this.unlisten = history.listen((location, action) => {
      store.dispatch(setPathname(location.pathname));
    });
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="mainReact">
            <ModalTutorial />
            <Navigation />
            <MODAL_SHARE_LINK />
            {/* Public */}
            <Route exact path="/" component={Page.DashboardPublic} />
            <Route exact path="/login" component={Page.LandingLogin} />
            <Route exact path="/register" component={Page.LandingRegister} />
            <Route
              exact
              path="/forget-password"
              component={Page.LandingForgetPassword}
            />
            {/* blog */}
            <Route exact path="/blog_read/:link" component={Page.BlogRead} />
            <Route
              exact
              path="/public/:mode"
              component={Page.DashboardPublic}
            />
            <Route exact path="/insight" component={Page.PARENT_INSIGHT} />
            <Route exact path="/sini" component={Page.PAGE_SINI} />

            {/* PROJECT, LAYER, BI */}
            <PrivateRoute
              exact
              path="/dashboard"
              component={Page.PROJECT_DASHBOARD}
            />
            <PrivateRoute
              exact
              path="/editor/:geo_project_link"
              component={Page.PARENT_MAP_EDITOR}
            />
            <PrivateRoute
              exact
              path="/editor/:geo_project_link/:geo_layer_link/:mode"
              // geo_layer_link: layer link || no_layer
              // mode: view || chat || notif
              component={Page.PARENT_MAP_EDITOR}
            />
            <Route
              exact
              path="/view/:geo_project_link"
              component={Page.PARENT_MAP_VIEWER}
            />
            <PrivateRoute exact path="/bi/:link" component={Page.BI_PAGE} />

            {/* Profile */}
            <PrivateRoute
              exact
              path="/user_profile"
              component={Page.UserDashboard}
            />
            <PrivateRoute
              exact
              path="/editprofile"
              component={Page.EditProfile}
            />
            <PrivateRoute
              exact
              path="/purchase_list"
              component={Page.PaymentList}
            />
            <PrivateRoute
              exact
              path="/routing_access"
              component={Page.RoutingAccess}
            />
            {/* Form */}
            <PrivateRoute
              exact
              path="/form_editor/:geo_layer_link"
              component={Page.CreateForm}
            />

            {/* Group & payment */}
            <PrivateRoute
              exact
              path="/user_group"
              component={Page.GROUP_LIST_DASHBOARD}
            />
            <PrivateRoute
              exact
              path="/group/:id"
              component={Page.GROUP_DETAIL}
            />
            <PrivateRoute
              exact
              path="/payment/:payment_id"
              component={Page.PaymentInstruction}
            />
            {/* Trash */}
            <PrivateRoute exact path="/recovery" component={Page.Recovery} />
            <style>
              {`
            #startingLoader{ display: none;}
            `}
            </style>
          </div>
          <SNACKBAR_PARENT />
        </Router>
      </Provider>
    );
  }
}
export default App;
