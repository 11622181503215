/* LIBRARY MODULE */
import React, { Component } from "react";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import ReactDOMServer from "react-dom/server";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/* NON IMPORT */

class BI_MARKER extends Component {
  state = {
    markers: [],
  };

  componentDidMount() {
    this.on_render();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.bi.trigger_rerender !== prevProps.bi.trigger_rerender ||
        this.props.layer.map_object !== prevProps.layer.map_object) &&
      this.props.layer.map_object
    ) {
      this.on_render();
    }
  }

  on_render = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;
    const { map_object } = this.props.layer;
    if (map_object) {
      this.cleanup_markers();
      const geo_layer_list = this.get_geo_layers(
        project_list,
        bi_object,
        project_object_selected,
        layer_id
      );
      geo_layer_list.forEach((data) => {
        const type = data.geo_layer?.type;
        const features_filtered = data.geo_layer?.geojson?.features || [];
        const label_config = data.geo_layer?.label_config || {};
        let features_center_marker = this.calculate_marker_centers(
          type,
          features_filtered
        );
        features_center_marker.forEach((item, idx) => {
          let marker_html = this.create_marker_content(
            item,
            label_config,
            data.geo_layer.fields,
            idx
          );
          const markerEl = document.createElement("div");
          markerEl.innerHTML = ReactDOMServer.renderToString(marker_html);
          const marker = new maplibregl.Marker({ element: markerEl })
            .setLngLat([
              item.geometry.coordinates[0],
              item.geometry.coordinates[1],
            ])
            .addTo(map_object);
          this.setState((prevState) => ({
            markers: [...prevState.markers, marker],
          }));
        });
      });
    }
  };

  calculate_marker_centers = (type, features_filtered) => {
    let features_center_marker = [];
    if (
      ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
        type
      )
    ) {
      features_center_marker = features_filtered.map((item) => {
        let feature = {};
        const geojson = {
          type: "FeatureCollection",
          features: [item],
        };
        feature = centroid(geojson, { properties: item.properties });
        return feature;
      });
    } else if (type === "Point") {
      features_center_marker = features_filtered;
    } else if (type === "LineString") {
      features_center_marker = features_filtered.map((item) => {
        const length_km = calculate_length(item, {
          units: "kilometers",
        }).toFixed(2);
        const center_length = length_km / 2;
        let point = along(item, center_length, { units: "kilometers" });
        point.properties = item.properties;
        return point;
      });
    }
    return features_center_marker;
  };

  create_marker_content = (item, label_config, fields, idx) => {
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1"; // Style mode: "style_1" or "style_2"
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";
    let label_image;
    let label_text;
    if (label_config?.is_use_label_image) {
      label_image = (
        <div>
          {label_image_field_key_array.map((field_key, idx) => {
            let content;
            if (item?.properties?.[field_key]) {
              content = (
                <div
                  key={idx}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                    backgroundImage: `url(${item?.properties?.[field_key]})`,
                    backgroundSize: "cover",
                  }}
                />
              );
            } else {
              content = <div key={idx} />;
            }
            return content;
          })}
        </div>
      );
    }
    if (label_config?.is_use_label_text) {
      label_text = (
        <div>
          {label_text_field_key_array.map((field_key, idx) => {
            const field = fields.find((item) => item.key === field_key);
            const field_type = field?.type;
            let text = item?.properties?.[field_key];
            if (["number"].includes(field_type)) {
              text = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(text);
            } else if (field_type === "currency") {
              text =
                "Rp." +
                new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  text
                );
            }
            let content;
            if (item?.properties?.[field_key]) {
              content = (
                <p
                  key={idx}
                  style={{
                    color:
                      idx === 0 ? text_color_primary : text_color_secondary,
                    backgroundColor:
                      idx === 0
                        ? background_color_primary
                        : background_color_secondary,
                    borderColor:
                      idx === 0
                        ? outline_color_primary
                        : outline_color_secondary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "3px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {text}
                </p>
              );
            } else {
              content = <div key={idx}></div>;
            }
            return content;
          })}
        </div>
      );
    }
    if (style_mode === "style_1") {
      return (
        <div>
          {label_image}
          {label_text}
        </div>
      );
    } else if (style_mode === "style_2") {
      const field_key_image_first = label_image_field_key_array[0];
      const image_first_url = item?.properties?.[field_key_image_first];
      let label_image_first;
      if (label_config?.is_use_label_image) {
        label_image_first = (
          <span
            className="badge_circle margin_right"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "2rem",
              backgroundImage: `url(${image_first_url})`,
              backgroundSize: "cover",
            }}
          />
        );
      }
      let field_key_text_first = label_text_field_key_array[0];
      const field = fields.find((item) => item.key === field_key_text_first);
      const field_type = field?.type;
      let text_first;
      if (label_config?.is_use_label_text) {
        text_first = item?.properties?.[field_key_text_first];
        if (["number"].includes(field_type)) {
          text_first = new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(text_first);
        } else if (field_type === "currency") {
          text_first =
            "Rp." +
            new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
              text_first
            );
        }
      }
      const label_text_field_key_array_wo_first = [
        ...label_text_field_key_array,
      ];
      label_text_field_key_array_wo_first.shift();
      let text_label;
      if (label_config?.is_use_label_text) {
        text_label = (
          <div>
            {label_text_field_key_array_wo_first.map((field_key, idx) => {
              const field = fields.find((item) => item.key === field_key);
              const field_type = field?.type;
              let text = item?.properties?.[field_key];
              if (["number"].includes(field_type)) {
                text = new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(text);
              } else if (field_type === "currency") {
                text =
                  "Rp." +
                  new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    text
                  );
              }
              let content;
              if (item?.properties?.[field_key]) {
                content = (
                  <p
                    key={idx}
                    style={{
                      color: text_color_secondary,
                      backgroundColor: background_color_secondary,
                      borderColor: outline_color_secondary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      padding: "3px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {text}
                  </p>
                );
              } else {
                content = <div key={idx}></div>;
              }
              return content;
            })}
          </div>
        );
      }
      return (
        <div>
          <div
            className="button_pill margin_bottom_minor"
            style={{
              paddingRight: label_image_first && text_first ? "15px" : "0px",
              color: text_color_primary,
              backgroundColor: background_color_primary,
              borderColor: outline_color_primary,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            {label_image_first}
            {text_first}
          </div>
          {text_label}
        </div>
      );
    }
  };

  cleanup_markers = () => {
    this.state.markers.forEach((marker) => marker.remove());
    this.setState({ markers: [] });
  };

  get_geo_layers = (
    project_list,
    bi_object,
    project_object_selected,
    layer_id
  ) => {
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];

    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });
    if (layer_id) {
      geo_layer_list = geo_layer_list.filter(
        (item) => item?.geo_layer?._id === layer_id
      );
    }
    return geo_layer_list;
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(BI_MARKER);
