import React, { useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import M_S_DEMOGRAPHY_ONE from "../libre_marker_sini/M_S_DEMOGRAPHY_ONE";
const color_line = "#104585";
const color_fill = "#104585";
const LAYER_S_DEMOGRAPHY_ONE = () => {
  const layer = useSelector((state) => state.layer);
  const sini = useSelector((state) => state.sini);
  const isFirstRender = useRef(true); // Ref to track first render

  const on_render_layer = useCallback(() => {
    const { map_object } = layer;
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
    } = sini;
    const visibility = sini_map_show.includes("demography_one")
      ? "visible"
      : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const demography_one = item?.demography_one;
        const layer_id_fill = `geojson_demography_one_${request_id}`;
        const layer_id_line = `geojson_demography_one_line_${request_id}`;
        if (demography_one?._id) {
          const geojson_demography_one = {
            type: "FeatureCollection",
            features: [demography_one],
          };
          // Add source
          if (!map_object.getSource(layer_id_fill)) {
            map_object.addSource(layer_id_fill, {
              type: "geojson",
              data: geojson_demography_one,
            });
          } else {
            map_object.getSource(layer_id_fill).setData(geojson_demography_one);
          }
          // Add fill layer
          if (!map_object.getLayer(layer_id_fill)) {
            map_object.addLayer({
              id: layer_id_fill,
              source: layer_id_fill,
              type: "fill",
              paint: {
                "fill-color": color_fill,
                "fill-opacity": 0.5,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_fill,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_fill,
              "fill-opacity",
              Number(sini_polygon_fill_opacity)
            );
          }
          // Add line layer
          if (!map_object.getLayer(layer_id_line)) {
            map_object.addLayer({
              id: layer_id_line,
              source: layer_id_fill,
              type: "line",
              paint: {
                "line-color": color_line,
                "line-width": 1,
                "line-opacity": 1,
                "line-gap-width": 0,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_line,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_line,
              "line-width",
              Number(sini_polygon_line_width)
            );
          }
        }
      });
    }
  }, [sini, layer]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip the first render
      return;
    }
    if (layer?.map_object) {
      on_render_layer();
    }
  }, [
    layer?.map_object,
    sini.state_update,
    sini.fly_init_update,
    on_render_layer,
  ]);

  const {
    fly_init_update,
    is_stop_zoom,
    request_id_active,
    sini_v2_list,
    ai_parent_detail_list,
  } = sini;
  const { map_object } = layer;

  useEffect(() => {
    if (fly_init_update && !is_stop_zoom) {
      const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
      if (merge_array.length > 0) {
        const sini_object = merge_array.find(
          (item) => item.request_id === request_id_active
        );
        if (sini_object) {
          const demography_one = sini_object?.demography_one;
          const long = sini_object?.long;
          const lat = sini_object?.lat;
          if (
            demography_one?._id &&
            demography_one?.geometry &&
            demography_one?.properties
          ) {
            if (map_object) {
              map_object.flyTo({
                center: [long, lat],
                zoom: 13,
              });
            }
          }
        }
      }
    }
  }, [
    map_object,
    ai_parent_detail_list,
    fly_init_update,
    is_stop_zoom,
    request_id_active,
    sini_v2_list,
  ]);

  return (
    <>
      <M_S_DEMOGRAPHY_ONE />
    </>
  );
};

export default LAYER_S_DEMOGRAPHY_ONE;
